import General from "./General.json"
import Marketplace from "./Marketplace.json"
import Profile from "./Profile.json"
import Sessions from "./Sessions.json"
import Notifications from "./Notifications.json"
import Jobs from "./Jobs.json"
import History from "./History.json"
import Reports from "./Reports.json"
import Language from "./Language.json"
import SignIn from "./SignIn.json"
import SignUp from "./SignUp.json"
import Help from "./Help.json"
import ManageJobs from "./ManageJobs.json"
import ManageProducts from "./ManageProducts.json"
import Home from "./Home.json"

export default {
	General,
	Marketplace,
	Profile,
	Sessions,
	Notifications,
	Jobs,
	History,
	Reports,
	Language,
	SignIn,
	SignUp,
	Help,
	ManageJobs,
	ManageProducts,
	Home
};
