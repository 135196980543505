import { ActionReport } from "../../redux/slices/components/ManageReports/manageReportsDataSlice"
import { MessageType } from "../chats/ChatFunctions"
import { GimGimmer } from "../requestsUtils/functionsRequests/offers/offersFunctions"

export type StatusReport = "OPENED" | "CLOSED" | "PENDING" | "ONPROGRESS"
export const statusReportValues: StatusReport[] = ["OPENED", "CLOSED", "PENDING"]
export type PriorityReport = "HIGH" | "MEDIUM" | "LOW"
export type Support = "Support_1" | "Support_2" | "Support_3" | "Support_Admin"
export type SupportHierarchy = 1 | 2 | 3 | 4

export type MovementSupport = {
    start: string
    end?: string
    actions: ActionReport[]
    userId: string,
    supportRole: Support
}
export interface UserReport {
    id: string
    actions: any
    timestamp: string
    description: string
    personsInvolved: string[]
    reason: string
    requestBy: string
    status: StatusReport
    userAssigned?: string
    support: SupportHierarchy
    seen: boolean
    openIncident: boolean
    movements?: MovementSupport[]
    requestByUserType: GimGimmer
    transactionId: string
}

export type DatesIncident = {
    startTime: string
    endTime?: string
}

export interface BugReport {
    id: string
    timestamp: string
    description: string
    priority: PriorityReport
    subject: string
    requestBy: string
    status: StatusReport
    userAssigned: string
    title: string
    datesIncident?: DatesIncident
}

//export type MessageType = "Message"

// DB MODEL dynamo
export type Message = {
    id: string
    __typename: MessageType
    chatRoomID: string
    content: string
    information: any
    createdAt: string
    updatedAt: string
    userID: string
    viewed: boolean
    isFirstGroupMessage?: boolean // just for web, ot in model
    isLastMessageFromOtherUser?: boolean
}