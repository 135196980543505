import React, { ReactNode, useEffect, useRef } from 'react'

type ClickOutsideProps = {
    exclude: string[] // id of button that open the modal
    onPress: () => void // press outside model
    children: ReactNode
    checkClickSameId?: boolean 
}

// Example checkClickSameId
/*
    true:
    Filters section, if click in an icon, it closes, with this variable, i can set an id to that icon and the component wont close

    false:
    Manage jobs menu edit, delete, link job, if click in another three dots button icon, it will close, to avoid have a lot of menus opened
*/

const ClickOutside = ({ onPress, children, exclude, checkClickSameId }: ClickOutsideProps) => {

    const divRef = useRef<HTMLDivElement>(null);
    const clickListenerRef = useRef<any>(null); // Ref to store the event listener function

    function hasChildWithId(element: HTMLElement, targetId: string) {
        // Base case: If the element itself has the target ID, return true
        if (element.id === targetId) {
          return true;
        }
      
        // Recursively check child nodes
        for (let i = 0; i < element.childNodes.length; i++) {
            const child = element.childNodes[i] as HTMLElement;
            if (hasChildWithId(child, targetId)) {
                return true;
            }
        }
      
        // If no child has the target ID, return false
        return false;
      }

    useEffect(() => {
        // Create a function to handle clicks
        const handleClick = (event: any) => {
            // when you click to open component, it closes always because detects the first click as "click outside", this code avoid that
            let firtstClick = false
            let clickOnSameId = false
            exclude.forEach((id: string) => {
                const element = document.getElementById(id)

                if(id === event.target?.id)
                    clickOnSameId = true
                //console.log("element", element, id, hasChildWithId(event.target,id))
                if(element && element.contains(event.target)){
                    firtstClick = true
                }
            })
            
            if(firtstClick || (clickOnSameId && checkClickSameId))
                return

            if (divRef.current && divRef.current.contains(event.target)) {
                //alert("General:inside")
            } else {
                onPress()
            }
        };
    
        // Assign the listener function to the ref
        clickListenerRef.current = handleClick;
    
        // Add the click event listener to the document body
        document.body.addEventListener('click', clickListenerRef.current);
    
        // Cleanup function to remove the listener on unmount
        return () => {
          document.body.removeEventListener('click', clickListenerRef.current);
        };
      }, [hasChildWithId]); // Empty dependency array to run only once on mount
  
    return (
        <div 
            ref={divRef} 
            //id={"click-outside"}
        >
            {children}
        </div>
    )
  }
export default React.memo( ClickOutside )