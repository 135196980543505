import React, { useCallback, useState } from 'react'
import "./ButtonOrder.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownArrowUp } from '@fortawesome/pro-solid-svg-icons'
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { orderOffersHomeReducer } from '../../../redux/slices/components/HomeOffers/homeOffersSlice'
import ToggleButton from '../../UIcomponents/ToggleButton/ToggleButton'

const ButtonOrder = () => {

    const dispatch = useAppDispatch()
    const type = useSelector((state: RootState) => state.homeOffers.type)
    const orderSelectedOption = useSelector((state: RootState) => state.homeOffers.orderSelectedOption)

    const [showOptions, setShowOptions] = useState(false)
    const options = [
        { id: "soonerDate", name: "Date" },
        { id: type === "Gim" ? "gimScore" : "gimmerScore", name: "Notation" },
        { id: "price", name: "Remuneration" },
        { id: "numberReviewsGimMER", name: "Number of tasks done" },
        { id: "numberReviewsGim", name: "Number of jobs done" },
        { id: "estimatedTime", name: "Time needed" },
    ]

    const handleShowOptions = (newValue: boolean) => {
        setShowOptions(newValue)
    }

    const onClose = useCallback(
        () => {
            handleShowOptions(false)
        },
        [],
    )
    
    const order = (id: string) => {
        dispatch( orderOffersHomeReducer(id) )
        //onClose()
    }

    return (
        <div className="">
            <button id="btn-order" onClick={() => handleShowOptions(!showOptions)} className="flex flex-ai-center order-btn pointer pl-20 pr-20">
                <FontAwesomeIcon icon={faArrowDownArrowUp} className="color-text fs-16" />
                <p className="fs-15 color-text ml-12">{"Filters"}</p>
            </button>
            <div className="p-relative">
                {
                    showOptions &&
                    <ClickOutside onPress={onClose} exclude={["btn-order", "icon-toggle"]} >
                        <div className="p-absolute order-options">
                            {
                                options.map((option) => {
                                    const isSelected = orderSelectedOption === option.id
                                    return(
                                        <div key={option.id} onClick={() => order(option.id)} className="option-order flex flex-ai-center flex-jc-sb width-100">
                                            <p className={`${isSelected ? (type === "Gim" ? "color-gim" : "color-gimmer") : "color-text"} fs-14`}>{option.name}</p>
                                            <ToggleButton checked={isSelected} onChange={order} id={option.id} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </ClickOutside>
                }
            </div>
        </div>
    )
}

export default React.memo( ButtonOrder )