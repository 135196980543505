import React from 'react'
import "./LeftHistoryTransaction.css"
import { RootState } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import BlockWorkPreference from '../../../UIcomponents/BlockWorkPreference/BlockWorkPreference'
import ImagesSkillSlider from '../../../ManageAllOffers/DetailedOfferScreen/ImagesSkillSlider/ImagesSkillSlider'

const LeftHistoryTransaction = () => {

    const history = useSelector((state: RootState) => state.myHistoryData.historyTransaction)
    const userId = useSelector((state: RootState) => state.auth.user.id)

    return (
        <div className="left-history-transaction">
            <div className="con-skills-slider-history">
                <ImagesSkillSlider id={userId} jobId={history.skill.jobId} type={history.gimId === userId ? "Job" : "Task"} imgKey={history.skill.imgKey} />
            </div>
            <BlockWorkPreference {...history.workPreference} />
        </div>
    )
}

export default React.memo( LeftHistoryTransaction )