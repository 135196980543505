import React, { useCallback, useState } from 'react'
import "./SearchSelectors.css"
import JobType from './JobType/JobType'
import When from './When/When'
import Where from './Where/Where'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { StageProcess, setOptionDisplayedSearch, setOptionSelectedWhereSearch } from '../../../redux/slices/components/HomeOffers/homeOffersSlice'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import useGetJobName from '../../hooks/useGetJobName/useGetJobName'
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { capitalizeAllWords } from '../../../utils/strings/strings'
import WhereIconsSeparated from '../../UIcomponents/WhereIconsSeparated/WhereIconsSeparated'
import InputJobType from './JobType/InputJobType/InputJobType'
import SearchBarMapHome from './Where/SearchBarMapHome/SearchBarMapHome'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../utils/colors/colors'



const SearchSelectors = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const isLogged = useSelector((state: RootState) => state.auth.isLogged)
    const optionDisplayed = useSelector((state: RootState) => state.homeOffers.optionDisplayed)
    const page = useSelector((state: RootState) => state.homeOffers.page)
    const limit = useSelector((state: RootState) => state.homeOffers.limit)
    const jobId = useSelector((state: RootState) => state.homeOffers.jobId)
    const where = useSelector((state: RootState) => state.homeOffers.where)
    const startTime = useSelector((state: RootState) => state.homeOffers.when?.startTime)
    const endTime = useSelector((state: RootState) => state.homeOffers.when?.endTime)
    const type = useSelector((state: RootState) => state.homeOffers.type)
    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)

    const onPressOption = (newOption: StageProcess) => {
        // if there is no option selected, the inside will be selected at the first time the modal is opened
        if(newOption === StageProcess.WHERE){
            if(optionSelectedWhere.length === 0){
                setTimeout(() => {
                    dispatch( setOptionSelectedWhereSearch("inside") )
                }, 50);
            }
        }
        dispatch( setOptionDisplayedSearch(newOption) )
    }

    const onCloseOptions = useCallback(
        () => {
            dispatch( setOptionDisplayedSearch(undefined) )
        },
        [],
    )

    const onSearch = () => {
        const params: any = {
            page: page.toString(),
            limit: limit.toString(),
            type,
        }

        if(jobId)
            params["jobId"] = jobId?.toString()

        if( startTime )
            params["startTime"] = startTime?.iso

        if( endTime )
            params["endTime"] = endTime?.iso

        if( where.outside && optionSelectedWhere.includes("outside") )
            params["outside"] = JSON.stringify(where.outside)

        if( where.inside && optionSelectedWhere.includes("inside") )
            params["inside"] = JSON.stringify(where.inside)

        if( optionSelectedWhere.includes("remote"))
            params["remote"] = JSON.stringify(true)

        const searchParams = createSearchParams(params);
        const pathname = isLogged ? "/home" : ""
        navigate({
            pathname,
            search: searchParams.toString(),
        });
    }

    const whenText = startTime ? 
                        `${capitalizeAllWords(moment(startTime.iso).format("D MMM")).replace(/\./, "")}${(endTime && !moment(startTime.iso).isSame( moment(endTime.iso), "day" ) ) ? ` - ${capitalizeAllWords(moment(endTime.iso).format("D MMM").replace(/\./, ""))}` : ""}` 
                        : ""

    return (
        <div className="selector-rearch flex p-relative">


            <button id="job" onClick={() => onPressOption(StageProcess.JOB)} 
                className={`search-option center ${!optionDisplayed ? "white-bg" : (optionDisplayed === StageProcess.JOB ? "sp-selected" : "")}`}
            >
                <div className="con-job-type">
                    <p className="fs-14 c-text text-align-left">{t("Home:index:label_01")}</p>
                    <InputJobType />
                </div>
            </button>



            <div className="height-100 center white-bg">
                <div className="sp-line">
                
                </div>
            </div>



            <button id="where" onClick={() => onPressOption(StageProcess.WHERE)} 
                className={`search-option center flex-d-column ${!optionDisplayed ? "white-bg" : (optionDisplayed === StageProcess.WHERE ? "sp-selected" : "")}`}
            >
                <div className="flex-1 center width-100">
                    <div className="con-job-type" >
                        <p className="fs-14 c-text text-align-left">{t("Home:index:label_02")}</p>
                        
                        <div className={`mt-2 ${!(optionSelectedWhere.length > 0 && optionDisplayed !== StageProcess.WHERE ) ? "display-none" : ""}`} id="btn-1-where">
                            <WhereIconsSeparated 
                                size={1.9} 
                                unit="rem" 
                                workPreference={where} 
                                backgroundColor={type === "Gimmer" ? Colors.Gimmer : Colors.Gim}
                            />
                        </div>
                        
                        
                        <div className={`mt-2 ${!(optionSelectedWhere.length === 0 && optionDisplayed !== StageProcess.WHERE ) ? "display-none" : ""}`} id="btn-2-where" >
                            <p className="fs-12 c-manatee text-align-left">{t("Home:index:label_03")}</p>
                        </div>
                        
                        {
                            optionDisplayed === StageProcess.WHERE &&
                            <div className="p-relative width-100 flex-1">
                                <SearchBarMapHome />
                            </div>
                        }
                    </div>
                </div>

                

            </button>



            <div className="height-100 center white-bg">
                <div className="sp-line">
                
                </div>
            </div>
            


            <div 
                className={`flex con-button-search ${ !optionDisplayed ? "white-bg" :  (optionDisplayed === StageProcess.WHEN ? "sp-selected" : "")}`}
            >
                <button id="when" onClick={() => onPressOption(StageProcess.WHEN)} className={`search-option-when center  ov-search-option-3`}>
                    <div className="flex flex-d-column">
                        <p className="fs-14 c-text text-align-left">{t("Home:index:label_04")}</p>
                        {
                            whenText ?
                            <p className="fs-14 c-text fw-600 mt-4 text-align-left">{whenText}</p>
                            : <p className="fs-14 c-manatee mt-4 text-align-left">{t("Home:index:label_05")}</p>
                        }
                    </div>
                </button>
                <div className="center ml-4">
                    <button onClick={onSearch} className="flex flex-ai-center dark-gray-bg pl-8 pr-8 button-search pointer">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="c-white fs-14" />
                        <p className="fs-14 c-white ml-6">{t("Home:index:label_06")}</p>
                    </button>
                </div>
            </div>

            


            <ClickOutside onPress={onCloseOptions} exclude={["job", "where", "when", "btn-2-where", "btn-1-where"]}>
                { optionDisplayed === StageProcess.JOB && <JobType /> }
                { optionDisplayed === StageProcess.WHERE && <Where /> }  
                { optionDisplayed === StageProcess.WHEN && <When /> }
            </ClickOutside>
            

        </div>    
    )
}

export default React.memo( SearchSelectors )