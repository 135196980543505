import React, { useCallback } from 'react'
import { faCartShopping } from '@fortawesome/pro-regular-svg-icons'
import GenericNotification from '../GenericNotification'
import { Colors } from '../../../../utils/colors/colors'
import { NotificationProps } from '../../FactoryNotification/FactoryNotification'

interface NewProductDeclinedNotificationProps extends NotificationProps {
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			approved: string, 
			receivedFromType: string, 
            userName: string,
            transactionId: string
		},
	}
}


const NewProductDeclinedNotification = ({ item, clickable, onSetNotificationAsView }: NewProductDeclinedNotificationProps) => {
	const { id, notifMsg, timestamp, viewed, notifData: { approved } } = item

	const touchNotification =  useCallback(
	  () => {
        if(!viewed)
            onSetNotificationAsView(id)
	  },
	  [],
	)
	
	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			

			id={id}
			timestamp={timestamp}
			viewed={clickable ? viewed : true}
			description={"New product declined"}
			title={"Username declined your product"}

			icon={ faCartShopping }
			color={ Colors.secondaryColor }
		/>
	)
}

export default React.memo( NewProductDeclinedNotification )