import React, { useCallback } from 'react'
import { TypeOffers } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { setStageProcessRequestReducer, TabNavigatorStage } from '../../../redux/slices/components/RequestProcess/requestProcessSlice'
import NextBlock from '../components/NextBlock/NextBlock'
import "./WhenOptionRequest.css"

type WhereOptionRequestProps = {
    type: TypeOffers
}

const WhenOptionRequest = ({ type }: WhereOptionRequestProps) => {

    
    const dispatch = useAppDispatch()

    const slotToRequest = useSelector((state: RootState) => state.requestProcess.slotToRequest)

    const onPressNext = useCallback(
        () => {
            dispatch( setStageProcessRequestReducer(TabNavigatorStage.RESUME) )
        },
        [],
    )
    

    return (
        <div className="flex flex-d-columnflex-1">
            <p className="fs-30-vh title-when-request text-align-center width-100">{"When will it take place?"}</p>
            <div className="flex-1">

            </div>
            {
                slotToRequest &&
                <NextBlock onPress={onPressNext} />
            }
        </div>
    )
}

export default React.memo( WhenOptionRequest )