import React, { useCallback, useState } from 'react'
import { Colors } from '../../../utils/colors/colors'
import WorkPreferenceColorDynamic from '../WorkPreferenceColorDynamic/WorkPreferenceColorDynamic'
import { WorkPreferenceStr } from '../../../utils/dbModels/HistoryTransaction'
import { WorkPreference } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import MapWithSearchBar from '../MapWithSearchBar/MapWithSearchBar'
import JobLabel from '../../SignUp/UIComponents/JobLabel/JobCertifiedLabel'
import { Position, getLocalitation } from '../../../utils/maps/locations'
import { useAppDispatch } from '../../../redux/store/store'
import { setInsideWorkPreference, setOutsideWorkPreference } from '../../../redux/slices/components/SignUp/SignUpSlice'
import useGetJobName from '../../hooks/useGetJobName/useGetJobName'
import { useTranslation } from 'react-i18next'

type SelectSkillLocationProps = {
    jobId: string
    isCertifReq: boolean
    handleWorkPreference: (workPreferenceStr: WorkPreferenceStr ) => void
    workPreference: WorkPreference
    remoteSelected: boolean
    insideSelected: boolean
    outsideSelected: boolean
}

const SelectSkillLocation = ({ jobId, isCertifReq, handleWorkPreference, workPreference, remoteSelected, insideSelected, outsideSelected }: SelectSkillLocationProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    
    const [currentWorkPreference, setCurrentWorkPreference] = useState<WorkPreferenceStr>("remote")

    const jobName = useGetJobName(jobId)

    const handleCurrentWorkPreference = (workPreferenceStr: WorkPreferenceStr) => {

        let newCurrentWorkPreference = workPreferenceStr
        let isTheLastActive = false
        if(workPreferenceStr === "remote" && remoteSelected){
            if(insideSelected)
                newCurrentWorkPreference = "inside"
            else if(outsideSelected)
                newCurrentWorkPreference = "outside"
            else
                isTheLastActive = true
        }else if(workPreferenceStr === "inside" && insideSelected){
            if(outsideSelected)
                newCurrentWorkPreference = "outside"
            else if(remoteSelected)
                newCurrentWorkPreference = "remote"
            else
                isTheLastActive = true
        }else if(workPreferenceStr === "outside" && outsideSelected){
            if(insideSelected)
                newCurrentWorkPreference = "inside"
            else if(remoteSelected)
                newCurrentWorkPreference = "remote"
            else
                isTheLastActive = true
        }

        if(isTheLastActive)
            return

        handleWorkPreference(workPreferenceStr)
        setCurrentWorkPreference(newCurrentWorkPreference)
    }

    
    const onChangePositionMap = useCallback(
        (newPosition: Position, radius?: number) => {
            getLocalitation(newPosition.latitude, newPosition.longitude)
            .then((data: any) => {
                if(radius){
                    dispatch( 
                        setOutsideWorkPreference({
                            latitude: newPosition.latitude,
                            longitude: newPosition.longitude,
                            address: data.results[0]?.formatted_address,
                            radius
                        }) 
                    )
                }  
                else{
                    dispatch(
                        setInsideWorkPreference({
                            latitude: newPosition.latitude,
                            longitude: newPosition.longitude,
                            address: data.results[0]?.formatted_address
                        }) 
                    )
                }
            }).catch((error) => {
                console.log("error", error)
            })
        },
        [],
    )

    return (
        <div className="">

            <div className="flex flex-d-column">

                <div className="flex flex-jc-sb flex-ai-start">

                    <JobLabel jobName={jobName} isCertifReq={isCertifReq} >
                        <p className="fs-36-vh color-text mr-30">{t("SignUp:selectWhereScreen:label_02")}</p>
                    </JobLabel>

                    <div className="flex flex-ai-center flex-jc-sb" style={{ width: "31.6%" }}>
                        <div className="flex flex-d-column flex-ai-center">
                            <button onClick={() => handleCurrentWorkPreference("remote")} className="pointer">
                                <WorkPreferenceColorDynamic type="remote" size="4.6vh" color={remoteSelected ? Colors.Gim : Colors.Manatee} />
                            </button>
                            <p className="fs-16-vh c-manatee mt-6">{t("SignUp:selectWhereScreen:label_03")}</p>
                        </div>

                        <div className="flex flex-d-column flex-ai-center">
                        <button onClick={() => handleCurrentWorkPreference("inside")} className="pointer">
                                <WorkPreferenceColorDynamic type="inside" size="4.6vh" color={insideSelected ? Colors.Gim : Colors.Manatee} />
                            </button>
                            <p className="fs-16-vh c-manatee mt-6">{t("SignUp:selectWhereScreen:label_04")}</p>
                        </div>

                        <div className="flex flex-d-column flex-ai-center">
                            <button onClick={() => handleCurrentWorkPreference("outside")} className="pointer">
                                <WorkPreferenceColorDynamic type="outside" size="4.6vh" color={outsideSelected ? Colors.Gim : Colors.Manatee} />
                            </button>
                            <p className="fs-16-vh c-manatee mt-6">{t("SignUp:selectWhereScreen:label_05")}</p>
                        </div>
                    </div>

                </div>  


                <p className="fs-18-vh color-text mt-10 mb-10">{ t("SignUp:selectWhereScreen:label_06") }</p>


            </div>

            <div className="flex flex-jc-end">
                <button>
                    <p className="fs-16vh"></p>
                </button>
            </div>

            <div className="p-relative center">
                {
                    currentWorkPreference === "remote" && <img src="../assets/svg/doctor_remote.svg" alt="remote" style={{ height: "38vh", marginTop: "4vh" }} />
                }

                {
                    currentWorkPreference === "inside" && 
                    <MapWithSearchBar 
                        address={workPreference.inside.address}
                        latitude={workPreference.inside.latitude} 
                        longitude={workPreference.inside.longitude}
                        showRadius={false}
                        onChangePositionMap={onChangePositionMap}
                        height="30vh"
                    />
                }

                {
                    currentWorkPreference === "outside" && 
                    <MapWithSearchBar 
                        address={workPreference.outside.address}
                        latitude={workPreference.outside.latitude} 
                        longitude={workPreference.outside.longitude}
                        showRadius={true}
                        onChangePositionMap={onChangePositionMap}
                        height="30vh"
                    />
                }

            </div>
            {
                (insideSelected || outsideSelected) &&
                <div
                    style={{
                        
                    }} 
                    className="gallery-bg p-8"
                >
                    {
                        remoteSelected &&
                        <div className="flex flex-ai-center">
                            <WorkPreferenceColorDynamic type="remote" size="2.8vh" color={Colors.TEXT} />
                            <p className="fs-16-vh color-text ml-10">{t("SignUp:selectWhereScreen:label_03")}</p>
                        </div>
                    }
                    {
                        insideSelected &&
                        <div className="flex flex-ai-center mt-8">
                            <WorkPreferenceColorDynamic type="inside" size="2.8vh" color={Colors.TEXT} />
                            <p className="fs-16-vh color-text ml-10">{workPreference.inside.address}</p>
                        </div>
                    }
                    {
                        outsideSelected &&
                        <div className="flex flex-ai-center mt-8">
                            <WorkPreferenceColorDynamic type="outside" size="2.8vh" color={Colors.TEXT} />
                            <p className="fs-16-vh color-text ml-10">{workPreference.outside.address}</p>
                        </div>
                    }
                </div>
            }

        </div>
    )
}

export default React.memo( SelectSkillLocation )