import React from 'react'
import { CategoryJob, JobCategoryJob } from '../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import InputList from '../InputList/InputList'
import useGetJobName from '../../hooks/useGetJobName/useGetJobName'
import { useTranslation } from 'react-i18next'

type InputCategoryJobsProps = {
    idJobSelected?: string
    categoryJobs: CategoryJob[]
    onPressJob: (job: JobCategoryJob) => void
    showError?: boolean 
}

const InputCategoryJobs = ({ idJobSelected, categoryJobs, onPressJob, showError }: InputCategoryJobsProps) => {

    const { t } = useTranslation()
    const jobName = useGetJobName(idJobSelected ?? "")

    return (
        <div className="padding-to-show-error input-list-simulator">
            <InputList 
                text={idJobSelected ? jobName : t("ManageProducts:table:label_08") ?? ""}  
                width="100%"
                height={"3vh"}
                fontSize="fs-14-vh"
            >
                {
                    categoryJobs
                    .map((category: CategoryJob) => {
                        return(
                            <div
                                key={category.id}
                                className="pointer"
                            >
                                <p className="fs-14-vh fw-700 flex flex-ai-center pl-14 pr-14 option-input-simulator">{category.categoryName}</p>
                                {
                                    category.jobs.map((job: JobCategoryJob) => {
                                        return(
                                            <button 
                                                key={job.id} 
                                                className="darks-white-bg pointer flex flex-ai-center pl-14 pr-14 width-100 pt-4 pb-4" 
                                                onClick={() => onPressJob(job)} 
                                                type="button" 
                                            >
                                                <p className="fs-14-vh pl-12 text-align-left">{job.jobName}</p>
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </InputList>
            {
                showError &&<p className="error-input-list p-absolute">{"Please select a job"}</p>
            }
        </div>  
    )
}

export default React.memo( InputCategoryJobs )