import { ChangeEvent, useCallback, useState } from 'react'
import { RootState, useAppDispatch } from "../../../../../redux/store/store";
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import UploadImageButton from "../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton";
import { getJobCategoryImagesLinks, updateJobsNamesFile } from "../../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions";
import { JobCategoryJob, updateEditedJobReducer } from "../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import { resetValueFromState } from "../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice";
import LoadingSpinner from "../../../../LoadingSpinner";
import axios from "axios";
import "./FormEditJobModal.css"
import PreviewImage from "../../../../UIcomponents/Modals/PreviewImage/PreviewImage";
import { S3DomainGimwork_categories_logo } from '../../../../../utils/staticVariables/staticVariables';
import { MyYup } from '../../../../../utils/verifications/myYup';
import { editJob } from '../../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions';
import ImgFormats from '../../../../UIcomponents/ImgFormats/ImgFormats';
import { useTranslation } from 'react-i18next';
import { resizeImage } from '../../../../../utils/images/resizeImages';
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../../utils/verifications/Forms';
import { useSelector } from 'react-redux';
import { setNameFileReducer } from '../../../../../redux/slices/components/Global/GlobalSlice';
import { getCheckDependentJobValues, setOptionalDataFromInputsJobCategory } from '../../../../../utils/categoryJobs/categoryJobs';
import TwoSelectorBar, { SideBarSelector } from '../../../../UIcomponents/TwoSelectorBar/TwoSelectorBar';
import RowPrincipal from '../../components/RowPrincipal/RowPrincipal';
import RowNightHours from '../../components/RowNightHours/RowNightHours';
import RowFixe from '../../components/RowFixe/RowFixe';
import RowTimeTransaction from '../../components/RowTimeTransaction/RowTimeTransaction';
import RowTransportCosts from '../../components/RowTransportCosts/RowTransportCosts';
import RowTips from '../../components/RowTips/RowTips';
import GeneralInputTitle from '../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle';
import RowProduct from '../../components/RowProduct/RowProduct';

type FormEditJobModalProps = {
    job: JobCategoryJob
    categoryId: string
}

const getStr = (str?: number): string => {
    return (str || str === 0) ? str.toString() : ""
}

const idInputImage = "new-edit-job-image"

const FormEditJobModal = ({ job, categoryId }: FormEditJobModalProps) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const jobsNamesFile = useSelector((state: RootState) => state.global.jobsNamesFile)

    const [isDay, setIsDay] = useState(true)
    const [nightCheckbox, setNightCheckbox] = useState(job.pricingInformation.night ? true : false)
    const [fixeCheckbox_Day, setFixeCheckbox_Day] = useState(job.pricingInformation.day.fixe ? true : false)
    const [timeTransactionCheckbox_Day, setTimeTransactionCheckbox_Day] = useState(job.pricingInformation.day.timeTransaction ? true : false)
    const [transportCheckbox_Day, setTransportCheckbox_Day] = useState(job.pricingInformation.day.transportCosts ? true : false)
    const [tipCheckbox_Day, setTipCheckbox_Day] = useState(job.pricingInformation.day.tips ? true : false)
    const [productCheckbox_Day, setProductCheckbox_Day] = useState(job.pricingInformation.day.product ? true : false)

    const [fixeCheckbox_Night, setFixeCheckbox_Night] = useState(job.pricingInformation.night?.fixe ? true : false)
    const [timeTransactionCheckbox_Night, setTimeTransactionCheckbox_Night] = useState(job.pricingInformation.night?.timeTransaction ? true : false)
    const [transportCheckbox_Night, setTransportCheckbox_Night] = useState(job.pricingInformation.night?.transportCosts ? true : false)
    const [tipCheckbox_Night, setTipCheckbox_Night] = useState(job.pricingInformation.night?.tips ? true : false)
    const [productCheckbox_Night, setProductCheckbox_Night] = useState(job.pricingInformation.night?.product ? true : false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        jobName: { error: "", value: jobsNamesFile?.[job.id]?.en ?? job.jobName, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        jobName_es: { error: "", value: jobsNamesFile?.[job.id]?.es ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        jobName_fr: { error: "", value: jobsNamesFile?.[job.id]?.fr ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},

        RFCcode: { error: "", value: job.RFCcode ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().min(8).max(8)},

        price_Day: {  error: "", value: job.pricingInformation.day.price.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        malusGim_Day: {  error: "", value: job.pricingInformation.day.malusGim.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        malusGimmer_Day: {  error: "", value: job.pricingInformation.day.malusGimmer.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        malusShareGim_Day: {  error: "", value: job.pricingInformation.day.malusShareGim.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        malusShareGimmer_Day: {  error: "", value: job.pricingInformation.day.malusShareGimmer.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        IVA_Day: {  error: "", value: job.pricingInformation.day.IVA.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        price_Night: { group: "principal_night", error: "", value: getStr(job.pricingInformation.night?.price), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        malusGim_Night: { group: "principal_night", error: "", value: getStr(job.pricingInformation.night?.malusGim), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        malusGimmer_Night: { group: "principal_night", error: "", value: getStr(job.pricingInformation.night?.malusGimmer), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        malusShareGim_Night: { group: "principal_night", error: "", value: getStr(job.pricingInformation.night?.malusShareGim), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        malusShareGimmer_Night: { group: "principal_night", error: "", value: getStr(job.pricingInformation.night?.malusShareGimmer), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        IVA_Night: { group: "principal_night", error: "", value: getStr(job.pricingInformation.night?.IVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},


        nightStartTime: { group: "hour", error: "", value: job.pricingInformation.night?.nightHours.startTime ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isHour()},
        nightEndTime: { group: "hour", error: "", value: job.pricingInformation.night?.nightHours.endTime ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isHour()},
        
        fixedPrice_Day: { group: "fixe_day", error: "", value: getStr(job.pricingInformation.day?.fixe?.price), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        fixedGimIVA_Day: { group: "fixe_day", error: "", value: getStr(job.pricingInformation.day?.fixe?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedGimmerIVA_Day: { group: "fixe_day", error: "", value: getStr(job.pricingInformation.day?.fixe?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedGimworkIVA_Day: { group: "fixe_day", error: "", value: getStr(job.pricingInformation.day?.fixe?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedISR_Day: { group: "fixe_day", error: "", value: getStr(job.pricingInformation.day?.fixe?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedRMP_Day: { group: "fixe_day", error: "", value: getStr(job.pricingInformation.day?.fixe?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        fixedPrice_Night: { group: "fixe_night", error: "", value: getStr(job.pricingInformation.night?.fixe?.price), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        fixedGimIVA_Night: { group: "fixe_night", error: "", value: getStr(job.pricingInformation.night?.fixe?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedGimmerIVA_Night: { group: "fixe_night", error: "", value: getStr(job.pricingInformation.night?.fixe?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedGimworkIVA_Night: { group: "fixe_night", error: "", value: getStr(job.pricingInformation.night?.fixe?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedISR_Night: { group: "fixe_night", error: "", value: getStr(job.pricingInformation.night?.fixe?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        fixedRMP_Night: { group: "fixe_night", error: "", value: getStr(job.pricingInformation.night?.fixe?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        timeTransactionGimIVA_Day: { group: "timeTransaction_day", error: "", value: getStr(job.pricingInformation.day?.timeTransaction?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        timeTransactionGimmerIVA_Day: { group: "timeTransaction_day", error: "", value: getStr(job.pricingInformation.day?.timeTransaction?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        timeTransactionGimworkIVA_Day: { group: "timeTransaction_day", error: "", value: getStr(job.pricingInformation.day?.timeTransaction?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        timeTransactionISR_Day: { group: "timeTransaction_day", error: "", value: getStr(job.pricingInformation.day?.timeTransaction?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        timeTransactionRMP_Day: { group: "timeTransaction_day", error: "", value: getStr(job.pricingInformation.day?.timeTransaction?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        
        timeTransactionGimIVA_Night: { group: "timeTransaction_night", error: "", value: getStr(job.pricingInformation.night?.timeTransaction?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        timeTransactionGimmerIVA_Night: { group: "timeTransaction_night", error: "", value: getStr(job.pricingInformation.night?.timeTransaction?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        timeTransactionGimworkIVA_Night: { group: "timeTransaction_night", error: "", value: getStr(job.pricingInformation.night?.timeTransaction?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        timeTransactionISR_Night: { group: "timeTransaction_night", error: "", value: getStr(job.pricingInformation.night?.timeTransaction?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        timeTransactionRMP_Night: { group: "timeTransaction_night", error: "", value: getStr(job.pricingInformation.night?.timeTransaction?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        
        transportCostsPrice_Day: { group: "transportCosts_day", error: "", value: getStr(job.pricingInformation.day?.transportCosts?.price), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        transportCostsGimIVA_Day: { group: "transportCosts_day", error: "", value: getStr(job.pricingInformation.day?.transportCosts?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsGimmerIVA_Day: { group: "transportCosts_day", error: "", value: getStr(job.pricingInformation.day?.transportCosts?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsGimworkIVA_Day: { group: "transportCosts_day", error: "", value: getStr(job.pricingInformation.day?.transportCosts?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsISR_Day: { group: "transportCosts_day", error: "", value: getStr(job.pricingInformation.day?.transportCosts?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsRMP_Day: { group: "transportCosts_day", error: "", value: getStr(job.pricingInformation.day?.transportCosts?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        transportCostsPrice_Night: { group: "transportCosts_night", error: "", value: getStr(job.pricingInformation.night?.transportCosts?.price), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        transportCostsGimIVA_Night: { group: "transportCosts_night", error: "", value: getStr(job.pricingInformation.night?.transportCosts?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsGimmerIVA_Night: { group: "transportCosts_night", error: "", value: getStr(job.pricingInformation.night?.transportCosts?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsGimworkIVA_Night: { group: "transportCosts_night", error: "", value: getStr(job.pricingInformation.night?.transportCosts?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsISR_Night: { group: "transportCosts_night", error: "", value: getStr(job.pricingInformation.night?.transportCosts?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        transportCostsRMP_Night: { group: "transportCosts_night", error: "", value: getStr(job.pricingInformation.night?.transportCosts?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        tipMin_Day: { group: "tip_day", error: "", value: getStr(job.pricingInformation.day?.tips?.tip), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        tipGimIVA_Day: {group: "tip_day", error: "", value: getStr(job.pricingInformation.day?.tips?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipGimmerIVA_Day: { group: "tip_day", error: "", value: getStr(job.pricingInformation.day?.tips?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipGimworkIVA_Day: { group: "tip_day", error: "", value: getStr(job.pricingInformation.day?.tips?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipISR_Day: { group: "tip_day", error: "", value: getStr(job.pricingInformation.day?.tips?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipRMP_Day: { group: "tip_day", error: "", value: getStr(job.pricingInformation.day?.tips?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        tipMin_Night: { group: "tip_night", error: "", value: getStr(job.pricingInformation.night?.tips?.tip), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1)},
        tipGimIVA_Night: { group: "tip_night", error: "", value: getStr(job.pricingInformation.night?.tips?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipGimmerIVA_Night: { group: "tip_night", error: "", value: getStr(job.pricingInformation.night?.tips?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipGimworkIVA_Night: { group: "tip_night", error: "", value: getStr(job.pricingInformation.night?.tips?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipISR_Night: { group: "tip_night", error: "", value: getStr(job.pricingInformation.night?.tips?.ISR), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        tipRMP_Night: { group: "tip_night", error: "", value: getStr(job.pricingInformation.night?.tips?.RMP), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        productGimIVA_Day: { group: "product_day", error: "", value: getStr(job.pricingInformation.day?.product?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        productGimmerIVA_Day: { group: "product_day", error: "", value: getStr(job.pricingInformation.day?.product?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        productGimworkIVA_Day: { group: "product_day", error: "", value: getStr(job.pricingInformation.day?.product?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        productRMPC_Day: { group: "product_day", error: "", value: getStr(job.pricingInformation.day?.product?.RMPC), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

        productGimIVA_Night: { group: "product_night", error: "", value: getStr(job.pricingInformation.night?.product?.gimIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        productGimmerIVA_Night: { group: "product_night", error: "", value: getStr(job.pricingInformation.night?.product?.gimmerIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        productGimworkIVA_Night: { group: "product_night", error: "", value: getStr(job.pricingInformation.night?.product?.gimworkIVA), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},
        productRMPC_Night: { group: "product_night", error: "", value: getStr(job.pricingInformation.night?.product?.RMPC), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(1).max(100)},

    }); 
    
    const [checkboxIsCertifReq, setCheckboxIsCertifReq] = useState<boolean>(job.isCertifReq || false)
    const [checkboxIsPool, setCheckboxIsPool] = useState<boolean>(job.isPool || false)
    const [checkboxIsGimmable, setCheckboxIsGimmable] = useState<boolean>(job.isGimmable || false)

    const [loading, setLoading] = useState<boolean>(false);
    const [jobImage, setJobImage] = useState<any>(null)
    const [categoryImage_100, setCategoryImage_100] = useState<any>(null)
    const [categoryImage_200, setCategoryImage_200] = useState<any>(null)
    const [categoryImage_300, setCategoryImage_300] = useState<any>(null)
    const [categoryImage_400, setCategoryImage_400] = useState<any>(null)
    const [categoryImage_500, setCategoryImage_500] = useState<any>(null)
    const [categoryImage_600, setCategoryImage_600] = useState<any>(null)
    const [categoryImage_700, setCategoryImage_700] = useState<any>(null)
    const [categoryImage_800, setCategoryImage_800] = useState<any>(null)

    const urlCurrentImage = `${S3DomainGimwork_categories_logo}${categoryId}/${job.id}/mainJobImg`
    //const urlCurrentImage = `${S3DomainGimwork_categories_logo}${job.categoryId}/${job.id}/mainJobImg`
    const [urlPreviewImage, setUrlPreviewImage] = useState<string>(urlCurrentImage)

    const [errorImage, setErrorImage] = useState<string>("");



    const handleSubmit = async(e: any) => {
        e.preventDefault();

        let existsError = false;
        for(const nameInput in inputValues){
            const checkDependentValue = getCheckDependentJobValues({
                nightCheckbox,
                fixeCheckbox_Day,
                timeTransactionCheckbox_Day,
                transportCheckbox_Day,
                tipCheckbox_Day,
                productCheckbox_Day,
            
                fixeCheckbox_Night,
                timeTransactionCheckbox_Night,
                transportCheckbox_Night,
                tipCheckbox_Night,
                productCheckbox_Night,
            },inputValues[nameInput].group)
            
            if(checkErrorsWhenSubmit(nameInput, inputValues, checkDependentValue))
                existsError = true


        }
        setInputValues({...inputValues})

        if(existsError)
            return

        if(!urlPreviewImage){
            if(!urlPreviewImage)
                setErrorImage(t("General:label_098") ?? "")
            return
        }

        try{
            
            const body: JobCategoryJob = {
                id: job.id,
                //categoryId: job.categoryId,
                active: job.active,
                imgKey: job.imgKey,
                isCertifReq: checkboxIsCertifReq,
                isGimmable: checkboxIsGimmable,
                isPool: checkboxIsPool,
                jobName: inputValues["jobName"].value.toString(),
                RFCcode: inputValues["RFCcode"].value.toString(), 
                linkedJobs: [],
                pricingInformation: {
                    day: {
                        price: Number(inputValues["price_Day"].value),
                        malusGim: Number(inputValues["malusGim_Day"].value),
                        malusGimmer: Number(inputValues["malusGimmer_Day"].value),
                        malusShareGim: Number(inputValues["malusShareGim_Day"].value),
                        malusShareGimmer: Number(inputValues["malusShareGimmer_Day"].value),
                        IVA: Number(inputValues["IVA_Day"].value),
                        timeTransaction: {
                            gimIVA: Number(inputValues["timeTransactionGimIVA_Day"].value),
                            gimmerIVA: Number(inputValues["timeTransactionGimmerIVA_Day"].value),
                            gimworkIVA: Number(inputValues["timeTransactionGimworkIVA_Day"].value),
                            ISR: Number(inputValues["timeTransactionISR_Day"].value),
                            RMP: Number(inputValues["timeTransactionRMP_Day"].value)
                        }
                    }
                }
            }

            setOptionalDataFromInputsJobCategory({
                inputValues,

                nightCheckbox,
                fixeCheckbox_Day,
                timeTransactionCheckbox_Day,
                transportCheckbox_Day,
                tipCheckbox_Day,
                productCheckbox_Day,
            
                fixeCheckbox_Night,
                timeTransactionCheckbox_Night,
                transportCheckbox_Night,
                tipCheckbox_Night,
                productCheckbox_Night,
            
                jobCategoryJob: body
            })
            
            setLoading(true)
            // get links
            //if( urlPreviewImage !== urlCurrentImage ){
            if(jobImage){
                //const folderS3CategoryName = job.imgKey.split("General:/")[0]
                //const imgName = Math.random().toString().replace(/\./, "0")
                //const imgName = 
                //const imgPath = `${folderS3CategoryName}-imgs-${imgName}`;
                const imgPath = `${categoryId}/${job.id}/mainJobImg`;
                //const imgPath = `${job.categoryId}/${job.id}/mainJobImg`;

                //const  data = await getJobCategoryImagesLinks({categoryName: folderS3CategoryName, imgName});
                const  data = await getJobCategoryImagesLinks({ imgName: imgPath });
                //console.log("data.imgKey", data.imgKey)
                const imgKeyLink = data.imgKey
                const imgKeyLink_100 = data.imgKey_100
                const imgKeyLink_200 = data.imgKey_200
                const imgKeyLink_300 = data.imgKey_300
                const imgKeyLink_400 = data.imgKey_400
                const imgKeyLink_500 = data.imgKey_500
                const imgKeyLink_600 = data.imgKey_600
                const imgKeyLink_700 = data.imgKey_700
                const imgKeyLink_800 = data.imgKey_800
                const options = { headers: { 'Content-Type': 'image/jpg, image/png' } };

                // save images
                    
                await Promise.all([
                    axios.put(imgKeyLink, jobImage, options),
                    axios.put(imgKeyLink_100, categoryImage_100, options),
                    axios.put(imgKeyLink_200, categoryImage_200, options),
                    axios.put(imgKeyLink_300, categoryImage_300, options),
                    axios.put(imgKeyLink_400, categoryImage_400, options),
                    axios.put(imgKeyLink_500, categoryImage_500, options),
                    axios.put(imgKeyLink_600, categoryImage_600, options),
                    axios.put(imgKeyLink_700, categoryImage_700, options),  
                    axios.put(imgKeyLink_800, categoryImage_800, options),
                ])
                body["imgKey"] = imgPath
            }

            await editJob(categoryId, job.id, body)
            //await editJob(job.id, body)

            const jobName_en = inputValues["jobName"].value;
            const jobName_es = inputValues["jobName_es"].value;
            const jobName_fr = inputValues["jobName_fr"].value;

            const bodyJobsNamesFile = {
                id: job.id,
                names: {
                    en: jobName_en.toString(),
                    es: jobName_es.toString(),
                    fr: jobName_fr.toString()
                }
            }

            await updateJobsNamesFile(bodyJobsNamesFile)

            dispatch( setNameFileReducer(bodyJobsNamesFile) )

            setLoading(false)
            dispatch(updateEditedJobReducer({categoryId, job: {...body, id: job.id}}))
            //dispatch(updateEditedJobReducer({categoryId: job.categoryId, job: {...body, id: job.id}}))
            dispatch(resetValueFromState())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Editing the job")
        }


    }


    const setImageUploaded = async() => {

        if(errorImage)
            setErrorImage("")

        const fileImage = (document.getElementById(idInputImage) as HTMLInputElement)?.files
        if(fileImage)
        {
            const file = fileImage[0]
            const [ 
                imageResized_100, 
                imageResized_200, 
                imageResized_300, 
                imageResized_400,
                imageResized_500,
                imageResized_600,
                imageResized_700,
                imageResized_800     
            ] = await Promise.all([
                resizeImage(file, 100, 100),
                resizeImage(file, 200, 200),
                resizeImage(file, 300, 300),
                resizeImage(file, 400, 400),
                resizeImage(file, 500, 500),
                resizeImage(file, 600, 600),
                resizeImage(file, 700, 700),
                resizeImage(file, 800, 800),
            ])
            setCategoryImage_100(imageResized_100)
            setCategoryImage_200(imageResized_200)
            setCategoryImage_300(imageResized_300)
            setCategoryImage_400(imageResized_400)
            setCategoryImage_500(imageResized_500)
            setCategoryImage_600(imageResized_600)
            setCategoryImage_700(imageResized_700)
            setCategoryImage_800(imageResized_800)
            
            setJobImage(file)
            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string")
                    setUrlPreviewImage(event.target?.result)
            }
        }
    }

    
    const onChangeSelectorBar = useCallback(
        (newSide: SideBarSelector) => {
            if(newSide === "left")
                setIsDay(true)
            else
                setIsDay(false)
        },
        [],
    )

    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const checkDependentValue = getCheckDependentJobValues({
            nightCheckbox,
            fixeCheckbox_Day,
            timeTransactionCheckbox_Day,
            transportCheckbox_Day,
            tipCheckbox_Day,
            productCheckbox_Day,
        
            fixeCheckbox_Night,
            timeTransactionCheckbox_Night,
            transportCheckbox_Night,
            tipCheckbox_Night,
            productCheckbox_Night,
        },inputValues[newEvent.target.name].group)
        //console.log("newEvent", newEvent.target.name, "dependent", checkDependentValue)
        checkErrors(newEvent, inputValues, setInputValues, checkDependentValue)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }

    return (
        <div className="flex flex-d-column">
            <div className="flex flex-jc-end flex-ai-center width-100 mb-10" style={{ height: "3.6vh" }}>
                <div className="mr-40">
                    <GeneralInputTitle 
                        title={t("ManageJobs:label_16")}
                        name={"RFCcode"}
                        value={inputValues["RFCcode"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={t("ManageJobs:label_16")}
                        error={inputValues["RFCcode"].error}
                    />
                </div>
                {
                    nightCheckbox &&
                    <TwoSelectorBar onChange={onChangeSelectorBar} initSelected={isDay ? "left" : "right"} colorMoving='#3A4153' width={"30rem"}>
                        <div className="gallery-bg" style={{ height: "3.6vh" }}>
                            hola
                        </div>
                        <div className="dark-gray-bg" style={{ height: "3.6vh" }}>
                            hey
                        </div>
                    </TwoSelectorBar>
                }
            </div>
            <form onSubmit={handleSubmit} className="flex width-100 mb-10 flex-1">
                <div className="flex flex-d-column" style={{ width: "30%" }}>
                    <div className="con-scroll-job-modal">
                        <div className="con-scroll-job-modal-aux">


                            <GeneralInputTitle 
                                title={`${t("General:label_072")} (${t("Language:Usa_lang")})`}
                                name={"jobName"}
                                value={inputValues["jobName"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={t("General:label_072")}
                                error={inputValues["jobName"].error}
                            />

                            <GeneralInputTitle 
                                title={`${t("General:label_072")} (${t("Language:Mexico_lang")})`}
                                name={"jobName_es"}
                                value={inputValues["jobName_es"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={t("General:label_072")}
                                error={inputValues["jobName_es"].error}
                            />

                            <GeneralInputTitle 
                                title={`${t("General:label_072")} (${t("Language:France_lang")})`}
                                name={"jobName_fr"}
                                value={inputValues["jobName_fr"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={t("General:label_072")}
                                error={inputValues["jobName_fr"].error}
                            />

                        
                            <div className="con-checkboxes-add-job">
                                <div className="con-checkbox-add-job">
                                    <input 
                                        type="checkbox" 
                                        id="isCertifReq" 
                                        name="isCertifReq" 
                                        onChange={() =>  setCheckboxIsCertifReq(!checkboxIsCertifReq) }
                                        checked={checkboxIsCertifReq}
                                    />
                                    <label className="label-checkbox-add-job fs-14-vh" htmlFor="isCertifReq">{t("General:label_094")} </label>
                                </div>
                                <div className="con-checkbox-add-job">
                                    <input 
                                        type="checkbox" 
                                        id="isPool" 
                                        name="isPool" 
                                        onChange={() => setCheckboxIsPool(!checkboxIsPool)}
                                        checked={checkboxIsPool}
                                    />
                                    <label className="label-checkbox-add-job fs-14-vh" htmlFor="isPool">{t("General:label_095")}</label>
                                </div>
                                <div className="con-checkbox-add-job">
                                    <input 
                                        type="checkbox" 
                                        id="isGimmable" 
                                        name="isGimmable" 
                                        onChange={() => setCheckboxIsGimmable(!checkboxIsGimmable)}
                                        checked={checkboxIsGimmable}
                                    />
                                    <label className="label-checkbox-add-job fs-14-vh" htmlFor="isGimmable">{t("General:label_096")}</label>
                                </div>
                            </div>

                            <input 
                                id={idInputImage}
                                className="display-none" 
                                type="file" 
                                accept="image/jpeg, image/png"
                                onChange={setImageUploaded}
                            />

                            <div className="con-upload-btns">
                                <div className="con-input add-job-upload-btn">
                                    <UploadImageButton 
                                        idInputFile={idInputImage} 
                                        text={t("General:label_097")}
                                        iconName="folder-image" 
                                    />
                                    { errorImage && <p className="error-input-file">{errorImage}</p> }
                                </div>
                            </div>

                            <PreviewImage 
                                imgLink={urlPreviewImage}
                                height="29.6vh"
                            />
                            
                            <ImgFormats />

                        </div>
                    </div>

                    <div className="flex flex-d-column">
                        <ConfirmButton text={t("General:label_016")} width="100%" />
                    </div>
                </div>

                <div className="flex-1 ml-20">


                    <RowPrincipal 
                        inputValues={inputValues}
                        isDay={isDay}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowTimeTransaction 
                        inputValues={inputValues}
                        isDay={isDay}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowNightHours 
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={nightCheckbox}
                        onChangeCheckbox={(value) => setNightCheckbox(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowFixe 
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? fixeCheckbox_Day : fixeCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setFixeCheckbox_Day(value) : setFixeCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />


                    <RowTransportCosts
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? transportCheckbox_Day : transportCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setTransportCheckbox_Day(value) : setTransportCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowTips
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? tipCheckbox_Day : tipCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setTipCheckbox_Day(value) : setTipCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowProduct
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? productCheckbox_Day : productCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setProductCheckbox_Day(value) : setProductCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                </div>

                { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default FormEditJobModal