import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GenericNotification from '../../GenericNotification';
import moment from 'moment';
import { convertPythonTimestamp } from '../../../../../utils/dates/dates';
import { GimGimmer } from '../../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import { Colors } from '../../../../../utils/colors/colors';
import { useNavigate } from 'react-router-dom';
import useGetUserInfo from '../../../../hooks/useGetUserInfo/useGetUserInfo';
import { NotificationProps } from '../../../FactoryNotification/FactoryNotification';

interface NewTransactionNotificationProps extends NotificationProps {
    item: {
        id: string;
        notifMsg: string;
        timestamp: number;
        viewed: boolean;
        iso_timestamp: string | number
        notifData: {
            receivedFromType: string;
            typeofNotyf: string
            jobName: string
            type: GimGimmer
            transactionId: string
            fromUserId: string
        };
    };
}

const NewTransactionNotification = ({
    item,
    clickable,
    onSetNotificationAsView,
}: NewTransactionNotificationProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { name } = useGetUserInfo(item.notifData.fromUserId)

    const {
        id,
        notifMsg,
        timestamp,
        viewed,
    } = item;

    // NOTE: ELIMINATE IF USER NOT EXISTS

    const touchNotification = useCallback(() => {
        navigate('TransactionInfo', {
			//id: item.notifData.transactionId,
		});
        if (!viewed) {
            onSetNotificationAsView(id);
        }
    }, []);

    if (item.notifData.typeofNotyf === 'New-event') {
        return (
            <GenericNotification
                touchNotification={touchNotification}
                id={item.id}
                timestamp={moment(convertPythonTimestamp(item.iso_timestamp ?? item.timestamp ?? 0)).valueOf()}
                viewed={item.viewed}
                description=						{`${t('Notifications:index:label_29')} ${t(
                    `Jobs:${item.notifData.jobName.replace(/\s/g, '')}`,
                )}`}
                title={item.notifData.type === 'Gimmer'
                    ? t('Notifications:index:label_27')
                    : t('Notifications:index:label_28')}
                icon={faCheckCircle}
                color={item.notifData.type === 'Gim' ? Colors.mainColor : Colors.secondaryColor}
            />
        );
    }


    return (
        <GenericNotification
            touchNotification={touchNotification}
            
            id={id}
            timestamp={timestamp}
            viewed={clickable ? viewed : true}
            description={`${name} ${t('Notifications:index:label_32')}`}
            title={	item.notifData.type === 'Gim'
                ? t('Notifications:index:label_30')
                : t('Notifications:index:label_31')}
            icon={faCheckCircle}
            color={item.notifData.type === 'Gim' ? Colors.mainColor : Colors.secondaryColor}
        />
    );
};

export default React.memo( NewTransactionNotification );
