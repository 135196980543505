import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import  "./JobType.css"
import CardJobType from './CardJobType/CardJobType'
import { setJobSearch, setOptionDisplayedSearch } from '../../../../redux/slices/components/HomeOffers/homeOffersSlice'
import { JobCategoryJob, JobCategoryJobWhole } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import useGetAllJobNames from '../../../hooks/useGetAllJobNames/useGetJobName'
import { useTranslation } from 'react-i18next'

const JobType = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const jobNames = useGetAllJobNames()

    const categories = useSelector((state: RootState) => state.managerJobData.categoryJobs)
    const text = useSelector((state: RootState) => state.homeOffers.searchJobInputText)
    const jobs = 
    [{ id: "FLEXIBLE" } as JobCategoryJobWhole]
    .concat(
        categories.flatMap(category => category.jobs.map((job) => ({ ...job, categoryId: category.id}))) 
        .filter((job) => {
            if(text){
                const jobName = jobNames[job.id]
                if(jobName.toLowerCase().indexOf(text.toLowerCase()) > -1)
                    return true
                else
                    return false
            }
                return true
        })
    )

    const onPress = useCallback(
        (jobId: string) => {
            dispatch( setJobSearch(jobId) )
            dispatch( setOptionDisplayedSearch(undefined) )
        },
        [],
    )
    
    return (
        <div className="con-jobs">
            <p className="fs-16 color-text mb-20">{t("Home:index:label_07")}</p>
            <div className="flex flex-wrap con-jobs-cards">
                {
                    jobs.map((job) => {
                        return (
                            <CardJobType 
                                key={job.id}
                                job={job}
                                onPress={onPress}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( JobType )