import React from 'react'
import PlaceAutocomplete from '../../../../UIcomponents/Maps/PlaceAutocomplete/PlaceAutocomplete'
import MapViewController from '../../../../UIcomponents/Maps/MapView/MapViewController'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store/store'
import { APIProvider } from '@vis.gl/react-google-maps'
import { useTranslation } from 'react-i18next'

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""

const SearchBarMapHome = () => {

    const { t } = useTranslation()

    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)
    const lastOptionClicked = optionSelectedWhere[optionSelectedWhere.length - 1]

    const setText = (selectedPlace: google.maps.places.PlaceResult | null) => {
        MapViewController.setSearch(selectedPlace)
    }

    return (
        <div className="width-100" style={{ zIndex: 3 }}>
            <APIProvider
                apiKey={API_KEY}
                solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'
            >
            {
                (lastOptionClicked === "inside" || lastOptionClicked === "outside") &&
                <PlaceAutocomplete className="fs-12 p-3" onPlaceSelect={setText} placeholder={t("Home:index:label_08") ?? ""} />
            }
            </APIProvider>
        </div>
    )
}

export default React.memo( SearchBarMapHome )