import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { MarketplaceProduct, MyProductBought } from '../../../../redux/slices/authSlice';
import { ProductType, setDeleteProductModalReducer, setEditProductModalReducer } from '../../../../redux/slices/components/Marketplace/marketplaceDataSlice';
import MarketplaceRow from './MarketplaceRow/MarketplaceRow';
import { getLinkToDownloadFileProduct } from '../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions';
import JsFileDownloader from 'js-file-downloader';

type MarketplaceRowsProps = {
    products: MyProductBought[] | MarketplaceProduct[]
    type: ProductType
    onPress: () => void
}

const MarketplaceRows = ({ products, type, onPress }: MarketplaceRowsProps) => {
    const dispatch = useAppDispatch()

    const openDeleteModal = useCallback(
        (id: string, productName: string, type: ProductType) => {
            dispatch( setDeleteProductModalReducer({
                id,
                productName,
                type
            }) )
        },
        [],
    )

    const openEditModal = useCallback(
        (product: MarketplaceProduct) => {
            dispatch( setEditProductModalReducer(product) )
        },
        [],
    )

    const downloadFile = useCallback(
        (idProduct: string, Name: string, type: ProductType, fileType: string) => {
            getLinkToDownloadFileProduct(idProduct, Name, type)
            .then((preSignedUrl) => {
                const link = document.createElement('a');
                link.href = preSignedUrl;
                link.setAttribute('download', `${Name}.${fileType}`); // Or any desired filename
                link.setAttribute("target", "_blank")
                document.body.appendChild(link);
                //link.click();
                new JsFileDownloader({ 
                    url: preSignedUrl
                  })
                  .then(function () {
                    // Called when download ended
                  })
                  .catch(function (error) {
                    // Called when an error occurred
                    console.log("error", error)
                  });
            }).catch(() => {

            })
        },
        [],
    )
        

    return (
        <div className="flex flex-d-column flex-1">
            {
                products.map((product) => {
                    return(
                        <MarketplaceRow
                            key={product.id}
                            id={product.id}
                            productName={product.productName}
                            price={product.price}
                            date={product.date}
                            description={product.description}
                            type={type}
                            File={product.File}
                            jobId={product.jobId}
                            productId={product.productId}
                            typeProduct={product.typeProduct}
                            onPress={onPress}
                            openDeleteModal={openDeleteModal}
                            openEditModal={openEditModal}
                            downloadFile={downloadFile}
                        />
                    )
                })
            }
        </div>
    )
}

export default React.memo( MarketplaceRows )