import i18n from "i18next";
import moment from "moment";

const words: any = { 
    "en": {
        isRequired: "Input is required",
        isString: "Input must be string",
        justLettters: "You can just enter letters from a to z",
        isNumber: "Input must be number",
        isPositive: "Number must be positive",
        max: "Input exceeds the maximum length",
        min: "Input is below the minimum length",
        isEmail: "Invalid email",
        length: "Must be of length",
        isHour: "Hour format not valid HH:MM"
    }, "es": {
        isRequired: "Dato requerido",
        isString: "EL dato debe de ser una cadena de texto",
        justLettters: "Solo puedes ingresar caracteres de la A a la Z",
        isNumber: "El dato debe de ser un número",
        isPositive: "El número debe de ser positivo",
        max: "El dato excede el máximo de longitud",
        min: "El dato está debajo del mínimo de longitud",
        isEmail: "Email inválido",
        length: "El dato debe de ser de longitud",
        isHour: "Formato hora no válido HH:MM"
    }, "fr": {
        isRequired : "Données requises",
        isString: "Les données doivent être une chaîne de texte",
        justLetters: "Vous ne pouvez saisir que les caractères de A à Z",
        isNumber: "La donnée doit être un nombre",
        isPositive: "Le nombre doit être positif",
        max: "Les données dépassent la longueur maximale",
        min: "Les données sont inférieures à la longueur minimale",
        isEmail : "E-mail invalide",
        length : "Les données doivent être de longueur",
        isHour: "Format heure non valide HH:MM"
    } 
}


export class MyYup {
    errorMsg: string = "";
    variable: string | number = "";
    language = "en"
    checkDependentValue?: boolean = true // if thevalue from which is dependent, for example a checkbox, is true, it must be check

    constructor(variable: string | number, language: string= "en", checkDependentValue?: boolean) {
        this.variable = variable
        this.language = language
        this.checkDependentValue = checkDependentValue
    }

    isRequired(msg: string = words[this.language].isRequired) {
        if(this.errorMsg)
            return this
  
        if(!this.variable && this.checkDependentValue){
            this.errorMsg = msg
        }
        return this
    }

    isRequiredDependent(msg: string = words[this.language].isRequired) {
        if(this.errorMsg)
            return this
        if(!this.variable && this.checkDependentValue){
            this.errorMsg = msg
        }
        return this
    }

    isString(msg: string = words[this.language].isString) {
        if(this.errorMsg)
            return this
        if(typeof this.variable !== "string"){
            this.errorMsg = msg
        }
        return this
    }
    justLetters(msg: string = words[this.language].justLettters){
        if(this.errorMsg)
            return this
        if(typeof this.variable !== "string"){
            this.errorMsg = words[this.language].isString
        }else if(!/^[A-Za-z\sàáâäãèéêëìíîïòóôöõùúûüçñÉÈÊÀÂÙÛ]*$/.test(this.variable.toString())){ // spaces are allowed
            this.errorMsg = msg
        }
        return this
    }

    justValidCharacters(msg: string = words[this.language].justLettters){
        if(this.errorMsg)
            return this
        if(typeof this.variable !== "string"){
            this.errorMsg = words[this.language].isString
        }else if(!/^[A-Za-z\sàáâäãèéêëìíîïòóôöõùúûüçñÉÈÊÀÂÙÛ0-9\s]*$/.test(this.variable.toString())){ // spaces are allowed
            this.errorMsg = msg
        }
        return this
    }

    justLettersAndUUID(msg: string = words[this.language].justLettters){
        if(this.errorMsg)
            return this
        if(typeof this.variable !== "string"){
            this.errorMsg = words[this.language].isString
        }else if(!/^[a-z0-9]+$/i.test(this.variable.toString().replace(/\s/g, "x").replace(/-/g, "x") )){ // spaces are allowed
            this.errorMsg = msg
        }
        return this
    }



    isNumber(msg: string = words[this.language].isNumber) {
        if(this.errorMsg)
            return this

        if( typeof Number(this.variable) === "number" && !isNaN(Number(this.variable))  ) 
            this.variable = Number(this.variable)
        if(typeof this.variable !== "number")
            this.errorMsg = msg
        return this
    }

    isPositive(msg: string = words[this.language].isPositive){
        if(this.errorMsg)
            return this
        if(typeof this.variable === "number" && this.variable < 0){
            this.errorMsg = msg
        }
        return this
    }

    max(max: number, msg: string = `${words[this.language].max} ${max}`){
        if(this.errorMsg)
            return this
        if(typeof this.variable === "number"){
            if(this.variable > max){
                this.errorMsg = msg
            }
            return this
        } else if(typeof this.variable === "string"){
            if(this.variable.length > max){
                this.errorMsg = msg
            }
            return this
        }
        return this
    }

    min(min: number, msg: string = `${words[this.language].min} ${min}`){
        if(this.errorMsg)
            return this
        if(typeof this.variable === "number"){
            console.log("this.variable > max", this.variable > min)
            if(this.variable < min){
                this.errorMsg = msg
            }
            return this
        } else if(typeof this.variable === "string"){
            if(this.variable.length < min){
                this.errorMsg = msg
            }
            return this
        }
        return this
    }

    length(length: number, msg: string = `${words[this.language].length} ${length}`){
        if(this.errorMsg)
            return this
        if(typeof this.variable === "number"){
            if(this.variable.toString().length !== length){
                this.errorMsg = msg
            }
            return this
        } else if(typeof this.variable === "string"){
            if(this.variable.length !== length){
                this.errorMsg =msg
            }
            return this
        }
        return this
    }

    isEmail(msg: string = words[this.language].isEmail){
        if(this.errorMsg)
            return this
        if(typeof this.variable !== "string"){
            this.errorMsg = words[this.language].isString
        }else if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.variable.toString().replace(/\s/g, "x"))){ // spaces are allowed
            this.errorMsg = msg
        }
        return this
    }

    isHour(msg: string = words[this.language].isHour){
        if(this.errorMsg)
            return this

        if(!moment(this.variable, 'HH:mm').isValid() || !/^\d{2}:\d{2}/.test(this.variable.toString())){
            this.errorMsg = msg
        }
        return this
    }

}


