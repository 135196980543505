import React from 'react'
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { useSelector } from 'react-redux';
import { ModalNameProducts, resetProductsDBModalsReducer } from '../../../redux/slices/components/ManageProducts/manageProductsDataSlice';
import EditProductDBModal from './EditProductDBModal/EditProductDBModal';
import DeleteProductDBModal from './DeleteProductDBModal/DeleteProductDBModal';
import "./ManageProductsModals.css"
import AddProductGimworkDBModal from './AddProductGimworkDBModal/AddProductGimworkDBModal';

const ManageProductsModals = () => {
    const dispatch = useAppDispatch()
    const modalName = useSelector((state: RootState) => state.manageProductsData.modalName);
  
    const handleClickOutsideModal = (e: any) => {
      const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
      if(!clickOutsideModal && e.target.id === "darkened-background-modal")
          dispatch( resetProductsDBModalsReducer() )
    }
  
    return (
      <>
        {
          modalName &&
          <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
              <div id={"background-aux-modal"}>
                  <ManagerProductsSelectModal modalName={modalName} />
              </div>
          </div>
        }
      </>
    )
}

interface ManagerProductsSelectModalProps {
    modalName: ModalNameProducts
  }
  
const ManagerProductsSelectModal = ({ modalName }: ManagerProductsSelectModalProps) => {
    
    switch(modalName){
      case "AddProductGimworkDBModal":
        return ( <AddProductGimworkDBModal /> )
      case "EditProductDBModal":
        return (<EditProductDBModal />)
      case "DeleteProductDBModal":
          return (<DeleteProductDBModal />)
      default:
          return(
              <h1>Error modals</h1>
          )
    }
    
}

export default React.memo( ManageProductsModals )