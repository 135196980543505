import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./UserCardUI.css"

type UserCardUIProps = {
    imgUrl: string
    text: string
    stars: number
    textStars: string
}


const UserCardUI = ({ imgUrl, text, stars, textStars }: UserCardUIProps) => {
    return (
        <div className="flex dark-gray-bg border-radius-3 width-100 user-card">
            <div className="flex flex-d-column flex-ai-center">
                <img src={imgUrl} alt="user-profile" className="img-user-card circle" />
                <div className="flex flex-ai-center mt-8">
                    <FontAwesomeIcon icon={faStar} className="color-star fs-12" />
                    <p className="fs-12 color-white ml-4">{`${stars} ${textStars}`}</p>
                </div>
            </div>

            <div className="ml-40 flex-1">
                <p className="fs-15 color-white mt-10">{text}</p>
            </div>
        </div>
    )
}

export default React.memo( UserCardUI )