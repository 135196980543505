import { faCheckCircle, faCircleX } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GenericNotification from '../../GenericNotification';
import moment from 'moment';
import { Colors } from '../../../../../utils/colors/colors';
import { useNavigate } from 'react-router-dom';
import { NotificationProps } from '../../../FactoryNotification/FactoryNotification';

interface TransactionWasCancelNotificationProps extends NotificationProps {
    item: {
        id: string;
        notifMsg: string;
        timestamp: number;
        viewed: boolean;
        iso_timestamp: string | number
        notifData: {
            receivedFromType: string;
            isTheUserTheOneWhoCancel: boolean
            jobName: string
            name: string
            startTime: string
            type: "GIMMER" | "GIM"
            fromUserId: string
        };
    }
}

const TransactionWasCancelNotification = ({
    item,
    clickable,
    onSetNotificationAsView,
}: TransactionWasCancelNotificationProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()


    const {
        id,
        notifData,
        timestamp,
        viewed,
    } = item;

    const touchNotification = useCallback(() => {
        navigate('TransactionInfo', {
			//id: item.notifData.transactionId,
		});
        if (!viewed) {
            onSetNotificationAsView(id);
        }
    }, []);


    const description = Boolean(notifData.isTheUserTheOneWhoCancel)  ?
        `${t('Notifications:index:label_51')} ${t(notifData.type === "GIMMER" ? 'Notifications:index:label_56' : 'Notifications:index:label_55')} ${t(`Jobs:${notifData.jobName.replace(/\s/g,'',)}`,
        )} ${t('Notifications:index:label_52')} ${moment(notifData.startTime).format('MMMM Mo')} ${t('Notifications:index:label_53')} ${moment(notifData.startTime).format('H:mm A')} ` :
        `${notifData.name} ${t('Notifications:index:label_54')} ${t(notifData.type === "GIMMER" ? 'Notifications:index:label_56' 
            : 'Notifications:index:label_55')} ${t(`Jobs:${notifData.jobName.replace(/\s/g,'', )}`,)} ${t('Notifications:index:label_52')} ${moment(notifData.startTime).format('MMMM Mo')} ${t('Notifications:index:label_53')} ${moment(notifData.startTime).format('H:mm A')}`
    
    return (
        <GenericNotification
            touchNotification={touchNotification}
            userIdImg={notifData.fromUserId}
            id={id}
            timestamp={timestamp}
            viewed={clickable ? viewed : true}
            description={description}
            title={notifData.type === "GIMMER" ? t('Notifications:index:label_58') : t('Notifications:index:label_59')}
            icon={faCircleX}
            color={notifData.type === "GIMMER" ? Colors.secondaryColor : Colors.mainColor}
        />
    );
};

export default React.memo( TransactionWasCancelNotification );
