import React, { useCallback } from 'react'
import WhereBlock from '../WhereBlock/WhereBlock'
import MapView from '../../Maps/MapView/MapView'
import { InsideWorkPreference, OutsideWorkPreference } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { goGoogleMapsWebsiteUrl } from '../../../../utils/maps/locations'
import "./MapBlock.css"

type OutsideMapBlock = {
    type: "outside"
    data: OutsideWorkPreference
}

type InsideMapBlock = {
    type: "inside"
    data: InsideWorkPreference
}

type MapBlockProps = {
    children: React.ReactNode
} & (OutsideMapBlock | InsideMapBlock)

const MapBlock = ({ type, children, data }: MapBlockProps) => {

    const isOutside = type === "outside"

    const onChangePositionMap = useCallback(
        () => {
            
        },
        [],
    )
    
    return (
        <div className="">
            <WhereBlock type={type} >
                {children}
            </WhereBlock>
            <div className="p-relative map-block">
                <MapView 
                    address={data.address}
                    initialLatitude={data.latitude}
                    initialLongitude={data.longitude}
                    radius={isOutside ? data.radius : 0}
                    showRadius={isOutside ? true : false}
                    onChangePositionMap={onChangePositionMap}
                    showSearchBar={false}
                />
            </div>
            <button onClick={() => goGoogleMapsWebsiteUrl(data.latitude, data.longitude)} className="gallery-bg p-12 fs-12 width-100 pointer text-align-left">
                {`${isOutside ? `${data.radius} km around` : ""} ${data.address}`}
            </button>
        </div>
    )
}

export default React.memo( MapBlock )