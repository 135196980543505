import React, { useCallback } from 'react'
import { faTimer } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../../utils/colors/colors'
import GenericNotification from '../GenericNotification'
import { NotificationProps } from '../../FactoryNotification/FactoryNotification'


interface ExpiredOfferNotificationProps extends NotificationProps {
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			receivedFromType: string, 
            nameOffer: string,
            type: string
		},
	}
}


const ExpiredRequestNotification = ({ item, clickable, onSetNotificationAsView }: ExpiredOfferNotificationProps) => {
	const { id, notifMsg, timestamp, viewed, notifData: { nameOffer, type } } = item
	const { t } = useTranslation()

	const touchNotification =  useCallback(
	  () => {
        if(!viewed)
            onSetNotificationAsView(id)
	  },
	  [],
	)
	
	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			

			id={id}
			timestamp={timestamp}
			viewed={clickable ? viewed : true}
			description={t("Notifications:index:label_47")}
			title={ `${t("Notifications:index:label_48")} ${t(`Jobs:${nameOffer.replace(/\s/g, "")}`)} ${t("Notifications:index:label_49")}` }

			icon={ faTimer }
			color={ type === "Gimmer" ? Colors.secondaryColor : Colors.mainColor }
		/>
	)
}

export default React.memo(ExpiredRequestNotification )