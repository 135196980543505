import React, { useCallback } from 'react'
import BlockRemote from '../components/BlockRemote/BlockRemote'
import BlockMap from '../components/BlockMap/BlockMap'
import { TypeOffers, WorkPreference } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import "./WhereOptionRequest.css"
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import NextBlock from '../components/NextBlock/NextBlock'
import { setStageProcessRequestReducer, TabNavigatorStage } from '../../../redux/slices/components/RequestProcess/requestProcessSlice'
import WhereOptionButtons from '../WhereOptionButtons/WhereOptionButtons'

type WhereOptionRequestProps = {
    workPreference: WorkPreference
    insideText: string
    outsideText: string
    type: TypeOffers
}

const WhereOptionRequest = ({ workPreference, insideText, outsideText, type }: WhereOptionRequestProps) => {

    const dispatch = useAppDispatch()

    const optionSelectedWhere = useSelector((state: RootState) => state.requestProcess.optionSelectedWhere)

    const onPressNext = useCallback(
        () => {
            dispatch( setStageProcessRequestReducer(TabNavigatorStage.WHEN) )
        },
        [],
    )
    

    return (
        <div className="flex-1 flex flex-d-column">
            <WhereOptionButtons type={type} />
            <div className="flex-1 flex flex-d-column">
                <div className="flex-1 flex con-options-where-opp">
                    { 
                        (workPreference.inside.address && optionSelectedWhere === "inside" ) && 
                        <BlockMap type="inside" data={workPreference.inside} >
                            <p className="fs-18-vh color-text fw-600 ml-10">{insideText}</p>
                        </BlockMap>
                    }

                    { 
                        (workPreference.remote && optionSelectedWhere === "remote" ) && 
                        <BlockRemote />
                    }

                    { 
                        (workPreference.inside.address && optionSelectedWhere === "outside" ) && 
                        <BlockMap type="outside" data={workPreference.outside} >
                            <p className="fs-18-vh color-text fw-600 ml-10">{outsideText}</p>
                        </BlockMap>
                    }
                    
                </div>
                <div className="flex-1"></div>
                <NextBlock onPress={onPressNext} />
            </div>
            
        </div>
    )
}

export default React.memo( WhereOptionRequest )