import React, { ChangeEvent, useState } from "react";
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../../redux/store/store";
import { RootState } from '../../../../../redux/store/store';
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import LoadingSpinner from "../../../../LoadingSpinner";
import { MyYup } from "../../../../../utils/verifications/myYup";
import { useTranslation } from "react-i18next";
import { checkErrors, ValuesForm } from "../../../../../utils/verifications/Forms";
import GeneralInputTitle from "../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle";
import InputCategoryJobs from "../../../../UIcomponents/InputCategoryJobs/InputCategoryJobs";
import { JobCategoryJob } from "../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import UploadImageButton from "../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton";
import "./FormRequestProductDBModal.css"
import { createProductDB, getLinkToPutPhysicalImage } from "../../../../../utils/requestsUtils/functionsRequests/products/productsFunctions";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { resetRequestProductsDBModalsReducer } from "../../../../../redux/slices/components/RequestProducts/requestProductsDataSlice";

type FormRequestProductDBModalProps = {

}


const FormRequestProductDBModal = ({  }: FormRequestProductDBModalProps) => {
    
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const categoryJobs = useSelector((state: RootState) =>state.managerJobData.categoryJobs)
    const typeProduct = useSelector((state: RootState) =>state.requestProductsData.typeProduct)

    const [jobSelected, setJobSelected] = useState<JobCategoryJob>()
    const [loading, setLoading] = useState(false)
    const [productImage, setProductImage] = useState<File>()
    const [urlPreviewImage, setUrlPreviewImage] = useState<string>("")

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justValidCharacters()},
        description: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
    })

    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }
 
    const onSelectJob = (job: JobCategoryJob) => {
        setJobSelected(job)
    }

    const setImageUploaded = async() => {

        const fileImage = (document.getElementById("new-add-product-image") as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]
            setProductImage(file)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }
  
    const handleSubmit = async(e: any) => {
        e.preventDefault()

        try {
            setLoading(true)
            const newId = uuidv4()
            const body = {
                id:             newId,
                jobId:          jobSelected?.id ?? "",
                typeProduct:    typeProduct,
                productName:    inputValues["productName"].value,
                description:    inputValues["description"].value
            }
            await createProductDB(body)

            if(typeProduct === "PHYSICAL"){
                const presignedLink = await getLinkToPutPhysicalImage(newId)

                const options = { headers: { 'Content-Type': 'image/jpeg, image/png' } };
    
                await axios.put(presignedLink, productImage, options)
            }

            dispatch( resetRequestProductsDBModalsReducer() )

        } catch (error) {
            console.log("error", error)
            alert("Error submitting product")
        }finally{
            setLoading(false)
        }
    }
     
    return (
        <div className="flex flex-d-column flex-1">

            <p className="fs-28-vh fw-600 mb-16">{"Add a new Product"}</p>

            <div className="p-relative flex-1">
                <div className="aux-scroll">
                    {
                        
                        <GeneralInputTitle 
                            title={t("ManageProducts:modals:label_03")}
                            name={"productName"}
                            value={inputValues["productName"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={t("ManageProducts:modals:label_04")}
                            error={inputValues["productName"].error}
                            width="100%"
                        />
                        
                    }

                    <GeneralInputTitle 
                        title={t("ManageProducts:modals:label_05")}
                        name={"description"}
                        value={inputValues["description"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={t("ManageProducts:modals:label_06")}
                        error={inputValues["description"].error}
                        width="100%"
                        textArea={{ height: "7vh" }}
                    />

                    <p className="fs-14-vh mb-2">{"For which job?"}<span className="color-presian-red">*</span></p>
                    <InputCategoryJobs idJobSelected={jobSelected?.id} onPressJob={onSelectJob} categoryJobs={categoryJobs} />

                    <div className="flex-1 mt-10">
                        {
                            typeProduct === "DIGITAL"
                            ? <></>
                            : 
                            <div>

                                <div className="gray-image width-100 mb-14">
                                    {
                                        urlPreviewImage &&
                                        <img src={urlPreviewImage} alt={"product"} className="width-100 height-100 contain" />
                                    }
                                </div>

                                <input 
                                    id="new-add-product-image"  
                                    className="display-none" 
                                    type="file" 
                                    accept="image/jpeg, image/png"
                                    onChange={setImageUploaded}
                                />
                                
                                <UploadImageButton 
                                    idInputFile="new-add-product-image"  
                                    text={t("General:label_097")}
                                    iconName="file-arrow-up" 
                                />

                            </div>
                        }
                    </div>

                </div>
            </div>
            
            <div className="pt-20">
                <ConfirmButton 
                    text={t("General:label_016")} 
                    width="100%"
                    onPress={handleSubmit}
                    disabled={!inputValues["productName"].value || !inputValues["description"].value || !jobSelected?.id || (typeProduct === "PHYSICAL" && !urlPreviewImage)}
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
      </div>
    )
}

export default React.memo( FormRequestProductDBModal )