import { faCheckCircle, faCircleX, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GenericNotification from '../../GenericNotification';
import moment from 'moment';
import { Colors } from '../../../../../utils/colors/colors';
import { useNavigate } from 'react-router-dom';
import { GimGimmer } from '../../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import useGetUserInfo from '../../../../hooks/useGetUserInfo/useGetUserInfo';

interface DeclinedRequestNotificationProps {
    item: {
        id: string;
        notifMsg: string;
        timestamp: number;
        viewed: boolean;
        iso_timestamp: string | number
        notifData: {
            receivedFromType: string;
            jobName: string
            type: GimGimmer
            fromUserId: string
        };
    };
    onSetNotificationAsView: (id: string) => void;
}

const DeclinedRequestNotification = ({
    item,
    onSetNotificationAsView,
}: DeclinedRequestNotificationProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { name } = useGetUserInfo(item.notifData.fromUserId)

    const {
        id,
        notifData,
        timestamp,
        viewed,
    } = item;

    const touchNotification = useCallback(() => {
        navigate('TransactionInfo', {
            //id: item.notifData.transactionId,
        });
        if (!viewed) {
            onSetNotificationAsView(id);
        }
    }, []);


    const description = notifData.type === 'Gimmer'? 
    `${name} ${t('Notifications:index:label_9')} ${t(`Jobs:${item.notifData.jobName.replace(/\s/g,'',)}`)}`
    : `${name} ${t('Notifications:index:label_10',)} ${t(`Jobs:${item.notifData.jobName.replace(/\s/g,'',)}`,)}`
    
    return (
        <GenericNotification
            touchNotification={touchNotification}
            userIdImg={notifData.fromUserId}
            id={id}
            timestamp={timestamp}
            viewed={viewed}
            description={description}
            title={notifData.type === "Gimmer" ? t('Notifications:index:label_7') : t('Notifications:index:label_8')}
            icon={faCircleXmark}
            color={notifData.type === "Gimmer" ? Colors.secondaryColor : Colors.mainColor}
        />
    );
};

export default React.memo( DeclinedRequestNotification );
