import NavBarBtn from '../NavBarBtn/NavBarBtn'
import "./NavBar.css"
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback, useState } from 'react';
import SettingsList from '../SettingsList/SettingsList';
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside';
import ModalLanguageCurrency from '../../Language/ModalLanguageCurrency/ModalLanguageCurrency';
import { setShowModalLanguageCurrency } from '../../../redux/slices/authSlice';
import ButtonSettingsNavBar from '../ButtonSettingsNavBar/ButtonSettingsNavBar';
import { useNavigate } from 'react-router-dom';

type NavBarProps = {
    isPublic: boolean
    hideLanguage: boolean
}

const NavBar = ({ isPublic, hideLanguage }: NavBarProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [showList, setShowList] = useState(false)

    const isLogged = useAppSelector((state: RootState) => state.auth.isLogged);
    const imgUrl = useAppSelector((state: RootState) => state.auth.user.imgUrl);
    

    const handleList = () => {
        setShowList(!showList)
    }

    const closeList = useCallback(
        () => {
            setShowList(false)
        },
        [],
    )

    const openLanguageCurrencyModal = () => {
        dispatch( setShowModalLanguageCurrency(true) )
    }
    
    const goHome = () => {
        if(isLogged)
            navigate("/home")
        else
            navigate("/")
    }

    return (
        <div className="NavBar-container center">
            <div className="max-width-container">

                {
                    !hideLanguage &&
                    <div onClick={goHome} className="pointer">
                        <img 
                            src="/name.svg" 
                            className="logo-word-gimwork"  
                            alt="gimwork" 
                        />
                    </div>
                }

                <div className="flex flex-ai-center">

                    <button onClick={openLanguageCurrencyModal}>
                        <img 
                            id="globe-btn"
                            src="/assets/svg/globe.svg" 
                            alt="globe" 
                            className="globe-img mr-24 pointer" 
                        />
                    </button>

                    <div className={`navbar-btns-container`}>
                        
                        <div className="center height-100">
                            <ButtonSettingsNavBar 
                                imgUrl={imgUrl}
                                showList={showList}
                                handleList={handleList}
                                closeList={closeList}
                            >
                                <SettingsList isPublic={isPublic} />
                            </ButtonSettingsNavBar>
                        </div>

                    </div>

                </div>

            </div>

            <ModalLanguageCurrency isLogged={isLogged} />

        </div>
    )
}

export default React.memo( NavBar )