import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import { Provider } from 'react-redux'
import { store } from './redux/store/store';
import './index.css';
import './AuxClasses.css';
import './App.css';
import "./normalize.css"
import "./fonts.css"
import MainComponent from './components/MainComponent/MainComponent';
import NotificationComponent from './utils/notifications/NotificationComponent';
import SignInProvidersListener from './utils/SignInProvidersListener/SignInProvidersListener';
import GetInitialData from './utils/GetInitialData/GetInitialData';

Amplify.configure(config);

function App() {

    if (window.location?.protocol !== 'https:') {
        //window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }

    return (
        <Provider store={store}>
            <NotificationComponent />
            <SignInProvidersListener />
            <GetInitialData />
            <div className='main-container'>
                <MainComponent />
            </div>
        </Provider> 

    );
}
/*
      <Provider store={store}>
          <div className='main-container'>
              <MainComponent />
          </div>
      </Provider> 
*/
export default App;
