import React from 'react'
import "./WhereOptionButtons.css"
import WorkPreferenceColorDynamic from '../../UIcomponents/WorkPreferenceColorDynamic/WorkPreferenceColorDynamic'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { Colors } from '../../../utils/colors/colors'
import { TypeOffers } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { WorkPreferenceStr } from '../../../utils/dbModels/HistoryTransaction'
import { setOptionSelectedWhereRequestProcessReducer } from '../../../redux/slices/components/RequestProcess/requestProcessSlice'

type WhereOptionButtonsProps = {
    type: TypeOffers
}

const WhereOptionButtons = ({ type }: WhereOptionButtonsProps) => {

    const dispatch = useAppDispatch()

    const optionsAvailableWhere = useSelector((state: RootState) => state.requestProcess.optionsAvailableWhere)
    const optionSelectedWhere = useSelector((state: RootState) => state.requestProcess.optionSelectedWhere)

    const setWorkPreference = (workPreferenceStr: WorkPreferenceStr) => {
        dispatch( setOptionSelectedWhereRequestProcessReducer(workPreferenceStr) )
    }

    return (
        <div className="flex flex-jc-sb flex-ai-center" style={{ height: "13.6vh" }}>

            {
                optionsAvailableWhere.includes("inside") &&
                <div className="flex flex-d-column flex-ai-center">
                    <button onClick={() => setWorkPreference("inside")} className="pointer">
                        <WorkPreferenceColorDynamic 
                            color={optionSelectedWhere === "inside" ? (type === "Task" ? Colors.Gim : Colors.Gimmer) : Colors.Manatee} 
                            size="4.6vh"
                            type="inside"
                        />
                    </button>
                    <p className="fs-16-vh color-manatee mt-2">{"My address"}</p>
                </div>
            }

            {
                optionsAvailableWhere.includes("remote") &&
                <div className="flex flex-d-column flex-ai-center">
                    <button onClick={() => setWorkPreference("remote")} className="pointer">
                        <WorkPreferenceColorDynamic 
                            color={optionSelectedWhere === "remote" ? (type === "Task" ? Colors.Gim : Colors.Gimmer) : Colors.Manatee} 
                            size="4.6vh"
                            type="remote"
                        />
                    </button>
                    <p className="fs-16-vh color-manatee mt-2">{"Remote"}</p>
                </div>
            }

            {
                optionsAvailableWhere.includes("outside") &&
                <div className="flex flex-d-column flex-ai-center">
                    <button onClick={() => setWorkPreference("outside")} className="pointer">
                        <WorkPreferenceColorDynamic 
                            color={optionSelectedWhere === "outside" ? (type === "Task" ? Colors.Gim : Colors.Gimmer) : Colors.Manatee} 
                            size="4.6vh"
                            type="outside"
                        />
                    </button>
                    <p className="fs-16-vh color-manatee mt-2">{"Remote"}</p>
                </div>
            }

        </div>
    )
}

export default React.memo( WhereOptionButtons )