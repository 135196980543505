import React from 'react'
import "./WhereBlock.css"
import WorkPreferenceIcon from '../../WorkPreferenceIcon/WorkPreferenceIcon'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'

type WhereBlockProps = {
    type: WorkPreferenceStr
    children: React.ReactNode
}

const WhereBlock = ({ type, children }: WhereBlockProps) => {
    return (
        <div className="where-block width-100 border-radius-3 flex gallery-bg flex-ai-center">
            <WorkPreferenceIcon type={type} size={3.8} />
            {children}
        </div>
    )
}

export default React.memo( WhereBlock )