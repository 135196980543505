import moment from 'moment';
import React, { useEffect, useState } from 'react'
import "./CalendarComponent.css"
import { capitalize } from '../../../utils/strings/strings';
import { GimGimmer } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import { WhenSearch } from '../../../redux/slices/components/HomeOffers/homeOffersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type CalendarComponentProps = {
    numberOfMonths: number
    onPress: (date: moment.Moment) => void
    type: GimGimmer
    when?: WhenSearch
}

type DayMonth = {
    obj: moment.Moment
    iso: string
    weekDay: number 
    day: number
    monthIndex: number
    milliseconds: number
    otherMonth?: "BEFORE" | "NEXT"
}

type MonthCalendar = {
    days: DayMonth[]
    monthIndex: number
    name: string
    firstDay: moment.Moment
    firstDayISO: string
    daysOfWeekNames: string[]
    year: number
}

const calendarScrollId = "scroll-calendar"

const CalendarComponent = ({ numberOfMonths, type, onPress, when }: CalendarComponentProps) => {

    const { t } = useTranslation()

    const [monthsData, setMonthsData] = useState<MonthCalendar[]>([])
    const [indexSelected, setIndexSelected] = useState(0)

    useEffect(() => {
      
        const months = getMonths(numberOfMonths)
        setMonthsData(months)

        return () => {
            
        }
    }, [numberOfMonths])

    const getLastDaysPreviosMonth = (firstDayOfMonth: moment.Moment): DayMonth[] => {
        const days: DayMonth[] = []
        const dayOtherMonth = firstDayOfMonth.clone().subtract(1, "day")
        //fll first day another month
        if(dayOtherMonth.day() < 6){
            let counter = dayOtherMonth.day()
            while(counter >= 0){
                days.push({
                    obj: dayOtherMonth.clone(),
                    iso: dayOtherMonth.toISOString(),
                    weekDay: dayOtherMonth.day(),
                    day: dayOtherMonth.date(),
                    monthIndex: dayOtherMonth.month(),
                    milliseconds: dayOtherMonth.valueOf(),
                    otherMonth: "BEFORE"
                })
                dayOtherMonth.subtract(1, "day")
                counter--
            }
        }
        return days
    }
    

    const getFirstDaysNextMonth = (lastDayOfMonth: moment.Moment): DayMonth[] => {
        const days: DayMonth[] = []
        const dayOtherMonth = lastDayOfMonth.clone().add(1, "day")
        //fll first day another month
        if(dayOtherMonth.day() < 7){
            let counter = dayOtherMonth.day()
            while(counter < 7){
                days.push({
                    obj: dayOtherMonth.clone(),
                    iso: dayOtherMonth.toISOString(),
                    weekDay: dayOtherMonth.day(),
                    day: dayOtherMonth.date(),
                    monthIndex: dayOtherMonth.month(),
                    milliseconds: dayOtherMonth.valueOf(),
                    otherMonth: "NEXT"
                })
                dayOtherMonth.add(1, "day")
                counter++
            }
        }
        return days
    }

    const getDaysCurrentMonth = (dayOfMonth: moment.Moment): DayMonth[] => {
        const days: DayMonth[] = []
        const dayMonth = dayOfMonth.clone()
        const currentMonthIndex = dayMonth.month()

        while(currentMonthIndex === dayMonth.month()){
            days.push({
                obj: dayMonth.clone(),
                iso: dayMonth.toISOString(),
                weekDay: dayMonth.day(),
                day: dayMonth.date(),
                monthIndex: dayMonth.month(),
                milliseconds: dayMonth.valueOf()
            })
            dayMonth.add(1, "day")
        }
    
        return days
    }
    

    const getMonths = (numberOfMonths: number): MonthCalendar[] => {
        // const oneMonthLater = now.clone().add(1, 'month');

        const now = moment();
        const firstDayOfMonth = now.clone().startOf('month');
        const endDayOfMonth = now.clone().endOf('month');

        const months: MonthCalendar[] = []

        let counter = 0;
        while(counter < numberOfMonths){
            const numberOfDays = firstDayOfMonth.daysInMonth();

            const daysInMonth = Array.from({ length: numberOfDays }, (_, i) => i + 1)


            const lastDaysPreviosMonth = getLastDaysPreviosMonth(firstDayOfMonth)
            const daysCurrentMonth = getDaysCurrentMonth(firstDayOfMonth)
            const firstDaysNextMonth = getFirstDaysNextMonth(endDayOfMonth.startOf("day"))


            const daysAsMomentObjects: DayMonth[] = lastDaysPreviosMonth
                .concat(daysCurrentMonth)
                .concat(firstDaysNextMonth)

            months.push({
                days: daysAsMomentObjects,
                monthIndex: firstDayOfMonth.month(),
                name: capitalize(firstDayOfMonth.format("MMMM")),
                firstDay: firstDayOfMonth,
                firstDayISO: firstDayOfMonth.toISOString(),
                daysOfWeekNames: moment.weekdays().map((str) => capitalize(str)),
                year: firstDayOfMonth.year()
            })

            firstDayOfMonth.add(1, 'month')
            endDayOfMonth.add(1, 'month')
            counter++;
        }
        return months
    }

    const handleSliderScroll = (index: number) => {

        if(index < 0 || index > numberOfMonths - 1)
            return

        const widthCalendar = 27.4
        const spaceBetweenMonths = 1.5
        const card = document.getElementById(`${calendarScrollId}-${index.toString()}`);
        const scrollContainer = document.getElementById(calendarScrollId);

        if(card && scrollContainer){
            const grayContainerData = scrollContainer.getBoundingClientRect();
            const cardData = card.getBoundingClientRect();

            //const cardLeftWithContainerBorderLeftPosition = (card.offsetLeft - grayContainerData.left)
            const cardLeftWithContainerBorderLeftPosition = (card.offsetLeft)

            const newPosition = cardLeftWithContainerBorderLeftPosition - (grayContainerData.width/2) + (cardData.width/2)

        
            let NewPosition = (index * (widthCalendar + spaceBetweenMonths))

            setIndexSelected(index)
            scrollContainer.scrollTo({
                left: NewPosition*10,
                behavior: "smooth"
            })
        }
    }


    const getClaseCircle = (currentCalendaDa: moment.Moment) => {
        const currentCalendaDay = currentCalendaDa.valueOf()
        let className = ""
        if(when?.startTime && when?.endTime){
            if(currentCalendaDay >= when?.startTime.milliseconds && currentCalendaDay <= when?.endTime?.milliseconds){
                className = type === "Gim" ? "calendar-gim-selected" : "calendar-gimmer-selected"
            }
        }else if(when?.startTime){
            if(currentCalendaDay === when?.startTime.milliseconds){
                className = type === "Gim" ? "calendar-gim-selected" : "calendar-gimmer-selected"
            }
        }
        return className
    }

    return (
        <div className="">
            <p className="fs-16 color-text mb-30">{t("Home:index:label_10")}</p>
            <div className="p-relative">

                <button 
                    onClick={() => handleSliderScroll(indexSelected-1)} 
                    className="left-arrow-calendar p-absolute pointer p-3 z-index-1"    
                    style={{ display: indexSelected > 0 ? "block" : "none", top: "0rem", left: 0 }}
                >
                    <FontAwesomeIcon 
                        icon={faChevronLeft} 
                        className="color-text fs-20" 
                    />
                </button>

                <button 
                    onClick={() => handleSliderScroll(indexSelected+1)} 
                    className="left-arrow-calendar p-absolute pointer p-3 z-index-1" 
                    style={{ display: indexSelected < (numberOfMonths - 2) ? "block" : "none", top: "0rem", right: 0 }}
                >
                    <FontAwesomeIcon 
                        icon={faChevronRight} 
                        className="color-text fs-20" 
                    />
                </button>
                
            </div>
            <div id={calendarScrollId} className="con-scroll-calendar p-relative">

                {
                    monthsData.map((month, indexM) => {
                        return (
                            <div key={month.name} id={`${calendarScrollId}-${indexM.toString()}`} className="month">
                                <div className="con-calendar-days">
                                    <p className="fs-18 color-text text-align-center mb-30">{`${month.name} ${month.year}`}</p>
                                    <div className="flex">
                                        {
                                            month.daysOfWeekNames.map((day) => {
                                                return(
                                                    <div key={day} className="calendar-day">
                                                        <p className="fs-12 color-text fw-500 day-of-week">{day.substring(0,2)}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="flex flex-wrap">
                                        {
                                            month.days.map((day, index) => {

                                                if(index > 34){
                                                    return ( 
                                                        // <div key={index} className="gimmer-bg calendar-day">{""}</div>
                                                        <React.Fragment key={day.day+"."+index}></React.Fragment>
                                                    )
                                                }

                                                if(day.monthIndex !== month.monthIndex){
                                                    return (
                                                        <div key={`${day.day}-${day.monthIndex}-${day.otherMonth}`} className="calendar-day center fs-14 opacity-0">{day.day}</div>
                                                    )
                                                }
                                                //console.log("day", day)
                                                const className = getClaseCircle(day.obj)

                                                return (
                                                    <button key={`${day.day}-${day.monthIndex}-${day.otherMonth}`} onClick={() => {
                                                        onPress(day.obj)
                                                    }} className={`calendar-day color-text fw-500 fs-14 pointer ${className}`}>
                                                        {day.day}
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( CalendarComponent )