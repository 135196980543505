import React, { useEffect } from 'react'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { setAfterMoveBackUp, setErrorMessageSupportChanged } from '../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const MessageAfterAcceptRefuse = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const messageAfterAcceptRefuse = useSelector((state: RootState) => state.manageUserReports.messageAfterAcceptRefuse)

    useEffect(() => {
      
        return () => {
            dispatch( setAfterMoveBackUp(false) )
        }

    }, [])
    
    if(!messageAfterAcceptRefuse)
        return <></>

    return (
        <div className="width-100 height-100 p-absolute center flex-d-column" style={{ backgroundColor: "white", zIndex: 2 }}>
            <p className="fs-24 color-text fw-500 text-align-center" style={{ width: "80%" }}>
                {"Your answer was sent succesfully!"}
            </p>
            <button className="pl-20 pr-20 dark-gray-bg border-radius-3 mt-40 pointer" onClick={() => navigate(-1)} style={{ height: "3.8rem", width: "20rem" }} >
                <p className="fs-18 fw-600 c-white">Go Back</p>
            </button>
        </div>
    )
}

export default React.memo( MessageAfterAcceptRefuse )