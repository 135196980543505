import React from 'react';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../../../redux/store/store';
import { activateJobReducer, JobCategoryJob } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { S3DomainGimwork_categories_logo } from '../../../../../../../utils/staticVariables/staticVariables'
import { resetValueFromState, setActivateJobState, setDeleteJobState, setEditJobState } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import "./JobRow.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import MenuJobRow from '../MenuJobRow/MenuJobRow';
import { addJobCategoryJobReducer, removeJobCategoryJobReducer } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import { useTranslation } from 'react-i18next';
import useCheckAccess from '../../../../../../hooks/useCheckAccess/useCheckAccess';
import useGetJobName from '../../../../../../hooks/useGetJobName/useGetJobName';
import StateColumnManageJobs from '../StateColumnManageJobs/StateColumnManageJobs';
import { activateJob } from '../../../../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions';


interface JobRowProps {
    job: JobCategoryJob,
    categoryId: string,
    categoryName: string,
    checkboxValue: boolean,
    handleEditJob: (job: JobCategoryJob, categoryId: string) => void
    handleActivateJob: (job: JobCategoryJob, categoryId: string) => void
    handleDeleteJob: (jobId: string) => void
}


const JobRow = React.memo(({ job, categoryId, categoryName, checkboxValue, handleEditJob, handleActivateJob, handleDeleteJob }: JobRowProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const accessDeleteJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Job", id_Action: "Delete" })
    const accessEditJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Job", id_Action: "Edit" })
    const accessEditActivateJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Activate_Deactivate", id_Action: "Edit" })
    const accessDisplayActivateJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Activate_Deactivate", id_Action: "Display" })

    const jobName = useGetJobName(job.id)
    
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [topMenu, setTopMenu] = useState<boolean>(false);

    const { price, malusGim, malusGimmer, malusShareGim, malusShareGimmer } = job.pricingInformation.day
    //console.log("Job id")
    const handleOpenJobMenu = () => {
        const conRows = document.getElementById(`container-rows-${categoryId}`)
        const currentRow = document.getElementById(`jobrow-${job.id}`)
        if(conRows && currentRow){
            // y is the absolute position to the screen, not to scroll container that is bigger like bottom and top
            const { y: topCon, height } = conRows.getBoundingClientRect()
            const { y: topRow } = currentRow.getBoundingClientRect()
            const bottomCon = topCon + height
            const halfContainer = (bottomCon - topCon)/2 + topCon
            if(topRow <  halfContainer)
                setTopMenu(false)
            else
                setTopMenu(true)
        }
        setShowMenu(!showMenu)
    }
  


    const handleJobCheckbox = (event: any) => {

        // These two lines avoid to select the row when we click in open menu or delete job
        const styleClasses: string = event.target.className.baseVal ?? event.target.className ?? ""
        const styleClassesParent: string = event.target.parentNode.className.baseVal ?? event.target.parentNode.className ?? ""

        if(styleClasses.indexOf("no-select") > -1 || styleClassesParent.indexOf("no-select") > -1)
            return

        if(!checkboxValue){
            dispatch(addJobCategoryJobReducer({
                categoryId,
                categoryName,
                id: job.id,
                jobName: job.jobName,
                active: job.active,
                isCertifReq: job.isCertifReq,
                isPool: job.isPool,
                isGimmable: job.isGimmable
            }))
        }else{
            dispatch(removeJobCategoryJobReducer({
                    categoryId,
                    id: job.id,
            }))
        }
    }

    const threeDotsIdBtn = `${job.id}-menu-modal`
    return (
        <div onClick={handleJobCheckbox} id={`jobrow-${job.id}`} className={`flex con-row-job con-row fs-12 pointer ff-roboto fw-500 width-100 ${checkboxValue ? "blue-row-background" : ""}`}>
            <div className='item1 center'>
                <input 
                    type="checkbox" 
                    className="pointer" 
                    onChange={() => {}}
                    checked={checkboxValue}
                />
            </div>
    
            {
                accessDisplayActivateJob &&
                <div className="flex flex-ai-center item2">
                    <div 
                        className={`circle-table
                            ${job.active ? "green" : "red"}
                        `}
                    ></div>
                    <label className="ml-4 mt-2">{job.active ? t("General:label_081") : t("General:label_082")}</label>
                </div>
            }
    

            <div className='item3 center'>
                <div 
                    className="img-table-row"
                    style={{"backgroundImage": `url("${S3DomainGimwork_categories_logo}${categoryId}/${job.id}/mainJobImg_100")`}}
                >
                </div>
            </div>

            
            <div className='item4 flex flex-ai-center'>
                {jobName}
            </div>
    
            <div className='item5'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.isCertifReq} />
                </div>
            </div>
    
            <div className='item6'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.isPool} />
                </div>
            </div>
    
            <div className='item7'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.isGimmable} />
                </div>
            </div>
            
            <div className='item8'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.pricingInformation.night?.nightHours ? true : false} />
                </div>
            </div>
    
            <div className='item9'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.pricingInformation.day.fixe ? true : false} />
                </div>
            </div>
    
            <div className='item10'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.pricingInformation.day.timeTransaction ? true : false} />
                </div>
            </div>
    
            <div className='item11'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.pricingInformation.day.transportCosts ? true : false} />
                </div>
            </div>

            <div className='item12'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.pricingInformation.day.tips ? true : false} />
                </div>
            </div>

            <div className='item13'>
                <div className="center height-100">
                    <StateColumnManageJobs state={job.pricingInformation.day.product ? true : false} />
                </div>
            </div>
    
            <div className='item14'>
                <div className="center height-100">
                xx
                </div>
            </div>
    
            <div className='item15'>
                <div className="center con-menu-icon-job-row height-100">
                    {
                        (accessEditJob || accessEditActivateJob) &&
                        <button
                            id={threeDotsIdBtn}
                            className={`btn-icon-table-job menu-icon-job-row pointer no-select ${showMenu ? "menu-clicked-job-row" : ""}`}
                            onClick={handleOpenJobMenu}
                        >
                            <FontAwesomeIcon icon={solid("ellipsis-vertical")} className="pointer no-select" />
                        </button>
                    }
    
                    { 
                        showMenu &&
                        <MenuJobRow 
                            btnId={threeDotsIdBtn}
                            job={job} 
                            categoryId={categoryId} 
                            categoryName={categoryName} 
                            accessDeleteJob={accessDeleteJob}
                            accessEditJob={accessEditJob}
                            accessEditActivateJob={accessEditActivateJob}
                            accessLinkJob={false}
                            topMenu={topMenu}
                            handleEditJob={handleEditJob}
                            handleActivateJob={handleActivateJob}
                            handleDeleteJob={handleDeleteJob}
                            setShowMenu={setShowMenu}
                        />
                    }
    
                </div>
            </div>
    
        </div>
      )
})

export default JobRow