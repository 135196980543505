import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import "./ExpandedImage.css"

type ExpandedImageProps = {
    url: string
    isVideo: boolean
    onClick: () => void
}

const ExpandedImage = ({
    url,
    isVideo,
    onClick
}: ExpandedImageProps) => {

    const [angle, setAngle] = useState<number>(0)

    const changeAngle = () => {
        if(angle === 270)
            setAngle(0)
        else
            setAngle(angle + 90)
    }

    return (
        <div className="fixed-all-screen background-dark center flex-d-column">
            
            {
                isVideo ?
                <video className="video-file" controls autoPlay muted>
                    <source src={url} type={`video/mp4`} />
                </video>
                :
                <div className="flex flex-d-column flex-ai-center">
                    <button onClick={changeAngle} className="center circle mb-20 pointer con-icon-close-id-img" >
                        <FontAwesomeIcon icon={regular("arrow-rotate-left")} className="fs-18 c-white" />
                    </button>
                    <div 
                        className="expanded-file" 
                        style={{ 
                            "backgroundImage":  `url(${url})`,
                            "rotate": `-${angle}deg`
                        }}
                    ></div>
                </div>
            }

            <div onClick={onClick} className="center circle mt-20 pointer con-icon-close-id-img"> 
                <FontAwesomeIcon icon={regular("times")} className="fs-18 c-white" />
            </div>

        </div>
    )
}

export default ExpandedImage