import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils/strings/strings';
import useGetJobName from '../../../hooks/useGetJobName/useGetJobName';
import "./LabelJobDate.css"
import { TypeOffers } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice';

//const timezone = moment.tz.guess();

type LabelJobDateProps = {
    date: string
    position?: "relative" | "absolute"
    jobId: string
    backgroundColor?: string
    type: TypeOffers
}

export const capitalizeDate = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    const words = s.split(" ")
    const weekDay = words[0]?.indexOf(".") === -1 ? words[0] + "." : words[0]
    const month = words[1]?.indexOf(".") === -1 ? words[1] + "," : words[1]
    return `${capitalize(weekDay)} ${capitalize(month).replace(/\./g, ",")} ${capitalize(words[2])}`
};

export const capitalizeFrenchDate = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    const words = s.split(" ")
    const weekDay = words[0]?.indexOf(".") === -1 ? words[0] + "." : words[0]
    const month = words[2]?.indexOf(".") === -1 ? words[2] + "," : words[2]
    return `${capitalize(weekDay)} ${capitalize(words[1])} ${capitalize(month)}`
};

const LabelJobDate = ({
    date,
    position = "absolute",
    backgroundColor = "#ffffff",
    jobId,
    type
}: LabelJobDateProps) => {
    const { i18n, t } = useTranslation(); 

    const job_Name = useGetJobName(jobId)

    moment.locale(i18n.language);
    const dateFormatted = i18n.language === "fr" ?
        capitalizeFrenchDate(moment(date).format('ddd DD MMM'))
        : capitalizeDate(moment(date).format('ddd MMM DD'))
    return (
        <div className="flex flex-ai-center flex-jc-sb width-100 con-label-job-date pt-6 pb-6 fs-18 fw-600 color-text" style={{ backgroundColor, position, opacity: 0.7 }}>
            <p className="flex-1 text-3-points-two-lines">{`${type === "Task" ? t("Home:index:label_12") : t("Home:index:label_13")} ${job_Name}`}</p>
            <p className="">{dateFormatted}</p>
        </div>
    )
}

export default React.memo(LabelJobDate)