import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Offer, OfferDB } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { getOfferPublic } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import { getProfilePictureByUserId, getUserDataById } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions';
import { getStars } from '../../../utils/numbers/numbers';
import useGetJobName from '../../../components/hooks/useGetJobName/useGetJobName';
import LoadingSpinner from '../../../components/LoadingSpinner';
import "./OpportunityScreen.css"
import ImagesSkillSlider from '../../../components/ManageAllOffers/DetailedOfferScreen/ImagesSkillSlider/ImagesSkillSlider';
import OpportunityButton from '../../../components/Opportunity/OpportunityButton/OpportunityButton';
import WhereIconsSeparated from '../../../components/UIcomponents/WhereIconsSeparated/WhereIconsSeparated';
import WhereBlock from '../../../components/UIcomponents/WhereBlocks/WhereBlock/WhereBlock';
import MapBlock from '../../../components/UIcomponents/WhereBlocks/MapBlock/MapBlock';
import ScheduleResume from '../../../components/UIcomponents/ScheduleResume/ScheduleResume';
import UserCardUI from '../../../components/UIcomponents/UserCard/UserCardUI/UserCardUI';
import Price from '../../../components/UIcomponents/Price/Price';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';

type UserData = {
    firstName: string
    lastName: string
    gimmerScore: number
    gimScore: number
}


const OpportunityScreen = () => {

    const { id }: any = useParams();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState<Offer>()
    const [imgUrl, setImgUrl] = useState("")

    const [userData, setUserData] = useState<UserData>()

     const isLogged = useSelector((state: RootState) => state.auth.isLogged)

    const jobName = useGetJobName(offer?.jobId)

    useEffect(() => {
      
        const getData = () => {
            getOfferPublic(id)
            .then((offer) => {

                const Offer: Offer = {
                    ...offer,
                    type: offer.estimatedTime ? "Task" : "Job",
                    timestampMilliseconds: new Date(offer.timestamp).getTime(),
                    seen: Boolean(offer.seen)
                }

                setOffer(Offer)
                
                getProfilePictureByUserId(offer.userId, "small")
                .then((imgUrl) => {
                  setImgUrl(imgUrl)
                }).catch(() => {})

                getUserDataById(offer.userId, { firstName: 1, lastName: 1, gimmerScore: 1, gimScore: 1, numberReviews: 1 })
                .then((userInfo: any) => {
                    setUserData({
                        firstName: userInfo.firstName ?? "",
                        lastName: userInfo.lastName ?? "",
                        gimmerScore: getStars(userInfo.gimmerScore) ?? "",
                        gimScore: getStars(userInfo.gimScore) ?? "",
                    })
                }).catch(() => {

                })

                
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })



        }
    
        getData()

        

    }, [])

    const onPressBtn = () => {
        if(isLogged)
            navigate(`/home/opportunity/${id}/where`)
        else
            navigate("/login")
    }

    if(loading){
        return(
            <LoadingSpinner fullPage={true} />
        )
    }

    if(!offer){
        return(
            <div className="flex">
                <p className="fs-30">{"Offer does not exists"}</p>
            </div>
        )
    }

    return (
        <div className="center flex-1 height-100">
            <div className="flex flex-jc-sb con-opportunity height-100">

                <div className="left-opportunity">
                    <p className="fs-28 fw-600 color-text">{jobName}</p>

                    <div className="flex flex-jc-sb flex-ai-center mt-30 mb-30">
                        <p className="fs-18 fw-500 color-text">{offer.type === "Task" ? "Remuneration" : "Cost" }</p>

                        <Price 
                            otherUserID={offer.userId}
                            type={offer.type === "Task" ? "gim" : "gimmer"}
                            jobId={offer.jobId}
                            categoryName={offer.categoryName}
                            estimatedTime={offer.estimatedTime}
                            cssClasses={"fs-18 color-text"}
                            initText={"$"}
                        />
                    </div>

                    {
                        userData &&
                        <UserCardUI 
                            stars={offer.type === "Task" ? userData.gimmerScore : userData.gimScore}
                            textStars={offer.type === "Task" ? "Gimmer" : "Gim"}
                            imgUrl={imgUrl}
                            text={offer.type === "Task" 
                                ? `Hello ! I'm ${userData.firstName} and I need to hire a French teacher for ${offer.estimatedTime}`
                                : `Hello ! I'm ${userData.firstName} and I want to work for you as French teacher`
                            }
                        />
                    }

                    <div className="width-100 mb-30 mt-30 con-img-slider-opp">
                        <ImagesSkillSlider 
                            id={offer.userId}
                            type={offer.type}
                            jobId={offer.jobId}
                            imgKey={offer.imgKey}
                        />
                    </div>

                    {
                        isLogged ?
                        <OpportunityButton onPress={onPressBtn} type={offer.type} />
                        : 
                        <button onClick={onPressBtn} className="btn-register-opp fs-15 fw-500">
                            Sign in
                        </button>
                    }
                </div>

                <div className="right-opportunity p-relative">
                    <div className="aux-scroll">
                        <div className="flex flex-jc-sb flex-ai-center mt-30 mb-16">
                            <p className="fs-20 color-text fw-600">{"Where"}</p>
                            <div className="flex">
                                <WhereIconsSeparated workPreference={offer.workPreference} size={2.6} unit="rem" />
                            </div>
                        </div>

                        {
                            offer.workPreference.remote &&
                            <WhereBlock type="remote" >
                                <div className="text-remote-block-opp">
                                    <p className="fs-16 color-text fw-500">{"Remote"}</p>
                                    <p className="fs-13 color-text mt-2">{ offer.type === "Task" ? "My Task can be done in remote / remotly" : "My job can be done in remote / remotly" }</p>
                                </div>
                            </WhereBlock>
                        }

                        {
                            offer.workPreference.inside.address && 
                            <div className="p-relative mt-16">
                                <MapBlock type="inside" data={offer.workPreference.inside} >
                                    <div className="text-remote-block-opp">
                                        <p className="fs-16 color-text fw-500">{"Adress"}</p>
                                    </div>
                                </MapBlock>
                            </div>
                        }

                        {
                            offer.workPreference.outside.address && 
                            <div className="p-relative mt-16">
                                <MapBlock type="outside" data={offer.workPreference.outside} >
                                    <div className="text-remote-block-opp">
                                        <p className="fs-16 color-text fw-500">{"Intervention area"}</p>
                                    </div>
                                </MapBlock>
                            </div>
                        }


                        <p className="fs-20 color-text mt-40 mb-30 fw-600">{"When"}</p>

                        {
                            offer.estimatedTime &&
                            <p className="fs-12 color-persian-red">{`The task will take ${offer.estimatedTime}`}</p>
                        }

                        <div className="mt-30">
                            <ScheduleResume detailsSchedule={offer.detailsSchedule} type={offer.type} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default OpportunityScreen