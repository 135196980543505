import React, { useCallback } from 'react'
import BlockType from '../BlockType/BlockType'
import MapView from '../../../UIcomponents/Maps/MapView/MapView'
import { InsideWorkPreference, OutsideWorkPreference } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { goGoogleMapsWebsiteUrl } from '../../../../utils/maps/locations'
import "./BlockMap.css"

type OutsideMapBlock = {
    type: "outside"
    data: OutsideWorkPreference
}

type InsideMapBlock = {
    type: "inside"
    data: InsideWorkPreference
}

type MapBlockProps = {
    children: React.ReactNode
} & (OutsideMapBlock | InsideMapBlock)

const BlockMap = ({ type, data, children }: MapBlockProps) => {

    const isOutside = type === "outside"

    const onChangePositionMap = useCallback(
        () => {
            
        },
        [],
    )

    return (
        <div className="block-map flex flex-d-column">
            <BlockType type="inside">
                {children}
            </BlockType>

            <div className="p-relative flex-1">
                <MapView 
                    address={data.address}
                    initialLatitude={data.latitude}
                    initialLongitude={data.longitude}
                    radius={isOutside ? data.radius : 0}
                    showRadius={isOutside ? true : false}
                    onChangePositionMap={onChangePositionMap}
                    showSearchBar={false}
                />
            </div>
            <button onClick={() => goGoogleMapsWebsiteUrl(data.latitude, data.longitude)} className="gallery-bg p-12 fs-15-vh width-100 pointer text-align-left">
                {`${isOutside ? `${data.radius} km around` : ""} ${data.address}`}
            </button>
        </div>
    )
}

export default React.memo( BlockMap )