
import { WorkPreference } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import HorizontalSeparator from '../../../UIcomponents/HorizontalSeparator/HorizontalSeparator';
import IconTextWorkPreference from '../IconTextWorkPreference/IconTextWorkPreference';
import React from 'react';

type WorkPreferenceIconsProps = {
    workPreference: WorkPreference
    distanceKm: number
}

const WorkPreferenceIcons = ({
    workPreference,
    distanceKm
}: WorkPreferenceIconsProps) => {
    return (
        <div className="flex flex-ai-center">
            {workPreference.outside.address && (
                <p className="color-text fs-17 fw-600">
                    {`${distanceKm > 10 ? "+10" : distanceKm.toFixed(1)}km`}
                </p>
            )}
            {workPreference.outside.address && <HorizontalSeparator distance={"1.2rem"} />}
            {workPreference.inside.address && (
                <IconTextWorkPreference
                    workPreference={'inside'}
                    size={"2rem"}
                />
            )}
            {workPreference.inside.address && <HorizontalSeparator distance={"1.2rem"} />}
            {workPreference.remote && (
                <IconTextWorkPreference
                    workPreference={'remote'}
                    size={"2rem"}
                />
            )}
        </div>
    )
}

export default React.memo( WorkPreferenceIcons )