import React from 'react'
import "./CardJobType.css"
import { JobCategoryJob, JobCategoryJobWhole } from '../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { S3DomainGimwork_categories_logo } from '../../../../../utils/staticVariables/staticVariables'
import useGetJobName from '../../../../hooks/useGetJobName/useGetJobName'
import { useTranslation } from 'react-i18next'

type CardJobTypeProps = {
    job: JobCategoryJobWhole
    onPress: (job: string) => void
}

const CardJobType = ({ job, onPress }: CardJobTypeProps) => {

    const { t } = useTranslation()
    const jobName = useGetJobName(job.id) || "I am flexible"

    const url = job.id === "FLEXIBLE" ? "/assets/images/Flexible_jpg_100.jpg" : `${S3DomainGimwork_categories_logo}${job.categoryId}/${job.id}/mainJobImg_300`

    return (
        <button onClick={() => onPress(job.id)} className="job-type pointer flex flex-d-column">
            <img src={url} alt={"job"} style={{ height: "74%", width: "100%" }}  />
            <div className="p-3 center flex-1 width-100">
                <p className="fs-14 color-text text-3-points">{job.id === "FLEXIBLE" ? t("Home:index:label_09") : jobName}</p>
            </div>
        </button>
    )
}

export default React.memo( CardJobType )