import React, { useCallback, useRef } from 'react'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'
import { useTranslation } from 'react-i18next'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { showAddProductGimworkDBModalReducer } from '../../../redux/slices/components/ManageProducts/manageProductsDataSlice'

const HeaderManageProducts = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const searchBarRef = useRef()

    const typeProduct = useSelector((state: RootState) => state.manageProductsData.typeProduct)
    const accessCreate = useCheckAccess({ id_Category: "Manage_Products", id_Policy: "Products", id_Action: "Create" })

    const searchSubmit = useCallback(
        () => {
            
        },
        [],
    )
    

    const handleRequestProduct = () => {
        dispatch( showAddProductGimworkDBModalReducer() )
    }

    return (
        <div className="flex flex-ai-center">
            <p className="title-screen">{t("General:label_273")}</p>
            <SearcherBar style={{ width: "28rem" }} ref={searchBarRef} searchSubmit={searchSubmit} placeholder={t("General:label_029")} />

            <button 
                className="btn-request-product border-color-text border-radius-6 flex flex-ai-center ml-20 pl-12 pr-12 pointer"
                onClick={handleRequestProduct}
            >
                <FontAwesomeIcon icon={regular("plus")} className="fs-18-vh" />
                <p className="fs-20-vh color-text ml-10">{typeProduct === "DIGITAL" ? t("ManageProducts:request:label_03") : t("ManageProducts:request:label_02")}</p>
            </button> 
            
        </div>
    )
}

export default React.memo( HeaderManageProducts )