import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Offer } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import useGetJobName from '../../../components/hooks/useGetJobName/useGetJobName';
import { getOfferPublic } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import { getUserDataById } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import "./WhereOpportunityScreen.css"
import { useTranslation } from 'react-i18next';
import WhereButtonsBar from '../../../components/WhereOpportunity/WhereButtonsBar/WhereButtonsBar';
import WhereOptionRequest from '../../../components/WhereOpportunity/WhereOptionRequest/WhereOptionRequest';
import WhereOptionButtons from '../../../components/WhereOpportunity/WhereOptionButtons/WhereOptionButtons';
import { setInitialWhereRequestProcessReducer, TabNavigatorStage } from '../../../redux/slices/components/RequestProcess/requestProcessSlice';
import WhenOptionRequest from '../../../components/WhereOpportunity/WhenOptionRequest/WhenOptionRequest';

type UserData = {
    firstName: string
    lastName: string
}



const WhereOpportunityScreen = () => {

    const { id }: any = useParams();

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const tabNavigatorStage = useSelector((state: RootState) => state.requestProcess.tabNavigatorStage)

    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState<Offer>()

    const [userData, setUserData] = useState<UserData>()

    const jobName = useGetJobName(offer?.jobId)

    const type = offer?.estimatedTime ? "Task" : "Job"

    useEffect(() => {
      
        const getData = () => {
            getOfferPublic(id)
            .then((offer) => {

                const Offer: Offer = {
                    ...offer,
                    type: offer.estimatedTime ? "Task" : "Job",
                    timestampMilliseconds: new Date(offer.timestamp).getTime(),
                    seen: Boolean(offer.seen)
                }

                setOffer(Offer)
                dispatch( setInitialWhereRequestProcessReducer( Offer.workPreference) )

                getUserDataById(offer.userId, { firstName: 1, lastName: 1, gimmerScore: 1, gimScore: 1, numberReviews: 1 })
                .then((userInfo: any) => {
                    setUserData({
                        firstName: userInfo.firstName ?? "",
                        lastName: userInfo.lastName ?? "",
                    })
                }).catch(() => {

                })

                
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })



        }
    
        getData()

    }, [])



    if(loading){
        return(
            <LoadingSpinner fullPage={true} />
        )
    }

    if(!offer){
        return(
            <div className="flex">
                <p className="fs-30">{"Offer does not exists"}</p>
            </div>
        )
    }

    return (
        <div className="center flex-d-column flex-1 height-100">
            <div className="con-where-opp flex-1 flex flex-d-column">

                <p className="fs-30-vh fw-600 color-text title-opp-request">{jobName}</p>

                <WhereButtonsBar type={type} />
                
                <div className="flex-1 flex flex-d-column">
                    {
                        tabNavigatorStage === TabNavigatorStage.WHERE &&
                        <WhereOptionRequest 
                            insideText={"My address"}
                            outsideText={`${userData?.firstName} address`}
                            workPreference={offer.workPreference} 
                            type={type}
                        />
                    }

                    {
                        tabNavigatorStage === TabNavigatorStage.WHEN &&
                        <WhenOptionRequest type={type} />
                    }

                </div>
                
            </div>
        </div>
    )
}

export default WhereOpportunityScreen