import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../utils/colors/colors';
import GenericNotification from '../GenericNotification';
import { NotificationProps } from '../../FactoryNotification/FactoryNotification';

interface CertiVerificationNotificationProps extends NotificationProps{
	item: {
		id: string;
		notifMsg: string;
		timestamp: number;
		viewed: boolean;
		notifData: {
			approved: string;
			receivedFromType: string;
			jobId: string;
			jobName: string;
		};
	};
}

const CertiVerificationNotification = ({
	item,
	clickable,
	onSetNotificationAsView,
}: CertiVerificationNotificationProps) => {
	const { t } = useTranslation()
	const {
		id,
		notifMsg,
		timestamp,
		viewed,
		notifData: { approved },
	} = item;
	const description =
		approved !== 'true'
			? `${t("Notifications:index:label_44")} ${t(`Jobs:${item.notifData.jobName.replace(/\s/g, "")}`)} ${t("Notifications:index:label_45")}`
			: `${t("Notifications:index:label_44")} ${t(`Jobs:${item.notifData.jobName.replace(/\s/g, "")}`)} ${t("Notifications:index:label_46")}`;

	const touchNotification = useCallback(() => {
		if (!viewed) {
			onSetNotificationAsView(id);
		}
	}, []);

	return (
		<GenericNotification
			touchNotification={touchNotification}
			
			id={id}
			timestamp={timestamp}
			viewed={clickable ? viewed : true}
			description={description}
			title={approved === 'true' ? t("Notifications:index:label_19") : t("Notifications:index:label_20")}
			icon={faGraduationCap}
			color={approved === 'true' ? Colors.flagGreen : Colors.secondaryColor}
		/>
	);
};

export default React.memo( CertiVerificationNotification );
