import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import NotificationCard from '../../../components/Notifications/NotificationCard/NotificationCard'
import "./NotificationsScreen.css"
import FactoryNotification from '../../../components/Notifications/FactoryNotification/FactoryNotification'
import { useNavigate, useNavigation } from 'react-router-dom'
import { setNotificationAsViewed, setNotificationsReducer } from '../../../redux/slices/authSlice'
import { getTokenFirebase, messaging } from '../../../utils/firebase/firebase'
import { onMessage } from 'firebase/messaging'
import { getNotificationDB, setNotificationAsViewedDB } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'

const NotificationsScreen = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const notifications = useSelector((state: RootState) => state.auth.user.notifications)

    const SetNotificationAsViewed = useCallback(
        (id: string) => {
            dispatch( setNotificationAsViewed(id) )
            setNotificationAsViewedDB(id)
            .then(() => {
    
            }).catch(() => {
                
            })
        },
        [],
    )
    
    const deleteNotification = useCallback(
        () => {
            
        },
        [],
    )
    
    useEffect(() => {
        getTokenFirebase()
        .then((currentToken) => {
        }).catch(() => {
        })

        getNotificationDB()
        .then((notifications) => {
            dispatch( setNotificationsReducer( notifications ) )
        }).catch(() => {

        })

    }, [])

    

    return (
        <div className="center height-flex-100">
            <div className="con-notifications-card flex flex-d-column height-100" style={{ paddingBottom: "2.5vh" }}>

                <div className="flex flex-d-column flex-jc-end con-title-notifications">
                    <p className="fs-24-vh fw-600 color-text text-align-left mb-20">Notifications</p>
                </div>

                <div className="p-relative width-100 height-100">
                    <div className="aux-scroll">
                        {
                            notifications.map((item) => {
                                return (
                                    <FactoryNotification
                                        item={item}
                                        key={item.id}
                                        //ModalProfile={this.ModalProfile}
                                        navigation={navigate}
                                        onSetNotificationAsView={SetNotificationAsViewed}
                                        deleteNotification={deleteNotification}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsScreen