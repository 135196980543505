import React, { useEffect } from 'react'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { setTextInputJobReducer } from '../../../../../redux/slices/components/HomeOffers/homeOffersSlice'
import useGetJobName from '../../../../hooks/useGetJobName/useGetJobName'
import { useTranslation } from 'react-i18next'

const InputJobType = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const text = useSelector((state: RootState) => state.homeOffers.searchJobInputText)
    const jobId = useSelector((state: RootState) => state.homeOffers.jobId)
    const jobName = useGetJobName(jobId)

    const onChangeText = (str: string) => {
        dispatch( setTextInputJobReducer(str) )
    }

    useEffect(() => {
        
        if(jobId)
            onChangeText(jobName)
        
    }, [jobName])
    

    return (
        <input 
            className="fs-13 c-manatee mt-4 text-align-left outline-none"
            placeholder={t("Home:index:label_07") ?? ""}
            style={{
                display: "block",
                width: "100%"
            }}
            value={text}
            onChange={(e) => onChangeText(e.target.value)}
        />
    )
}

export default React.memo( InputJobType )