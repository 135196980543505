import React from 'react'
import WorkPreferenceIcon from '../../../UIcomponents/WorkPreferenceIcon/WorkPreferenceIcon'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'
import "./BlockType.css"

type BlockTypeProps = {
    type: WorkPreferenceStr
    children: React.ReactNode
}

const BlockType = ({ type, children }: BlockTypeProps) => {
    return (
        <div className="width-100 flex gallery-bg flex-ai-center block-type pl-10">
            <WorkPreferenceIcon type={type} size={3} unit={"vh"} />
            {children}
        </div>
    )
}

export default React.memo( BlockType )