import React, { useCallback, useState } from 'react'
import "./Where.css"
import { DefaultPosition, getLocalitation, goGoogleMapsWebsiteUrl, Position } from '../../../../utils/maps/locations'
import MapWithSearchBar from '../../../UIcomponents/MapWithSearchBar/MapWithSearchBar'
import WorkPreferenceColorDynamic from '../../../UIcomponents/WorkPreferenceColorDynamic/WorkPreferenceColorDynamic'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'
import { Colors } from '../../../../utils/colors/colors'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { setOptionSelectedWhereSearch, setWhereInsideSearch, setWhereOutsideSearch } from '../../../../redux/slices/components/HomeOffers/homeOffersSlice'
import WorkPreferenceIcon from '../../../UIcomponents/WorkPreferenceIcon/WorkPreferenceIcon'
import RemoteImage from '../../../UIcomponents/RemoteImage/RemoteImage'
import { useTranslation } from 'react-i18next'
import MapViewController from '../../../UIcomponents/Maps/MapView/MapViewController'


const Where = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const inside = useSelector((state: RootState) => state.homeOffers.where?.inside)
    const outside = useSelector((state: RootState) => state.homeOffers.where?.outside)
    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)
    const type = useSelector((state: RootState) => state.homeOffers.type)

    const lastOptionClicked = optionSelectedWhere[optionSelectedWhere.length - 1]
    
    const onChangePositionMap = useCallback(
        (newPosition: Position, radius?: number) => {
            getLocalitation(newPosition.latitude, newPosition.longitude)
            .then((data: any) => {
                if(radius){
                    dispatch(setWhereOutsideSearch({
                        latitude: newPosition.latitude,
                        longitude: newPosition.longitude,
                        address: data.results[0]?.formatted_address,
                        radius
                    }))
                }else{
                    dispatch(setWhereInsideSearch({
                        latitude: newPosition.latitude,
                        longitude: newPosition.longitude,
                        address: data.results[0]?.formatted_address
                    }))
                }
            }).catch((error) => {
                console.log("error", error)
            })
        },
        [],
    )

    const handleCurrentWorkPreference = (work: WorkPreferenceStr) => {
        dispatch( setOptionSelectedWhereSearch(work) )
    }

    const onClickAddress = () => {
        if(lastOptionClicked === "inside")
            goGoogleMapsWebsiteUrl(inside.latitude, inside.longitude)
        else if(lastOptionClicked === "outside")
            goGoogleMapsWebsiteUrl(outside.latitude, outside.longitude)
    }

    const onPressAddress = () => {
        if(lastOptionClicked === "remote")
            return

        MapViewController.returnCurrentPoint()
    }

    return (
        <div className="con-where">

            <div className="flex flex-jc-sb flex-ai-center mb-10">
                <p className="fs-16 color-text">{t("Home:index:label_03")}</p>

                <div className="flex flex-ai-center flex-jc-sb" style={{ width: "31.6%" }}>
                    <div className="flex flex-d-column flex-ai-center">
                        <button onClick={() => handleCurrentWorkPreference("remote")} className="pointer">
                            <WorkPreferenceColorDynamic type="remote" size="3rem" color={optionSelectedWhere.includes("remote") ? (type === "Gimmer" ? Colors.Gimmer : Colors.Gim ) : Colors.Manatee} />
                        </button>
                    </div>

                    <div className="flex flex-d-column flex-ai-center">
                        <button onClick={() => handleCurrentWorkPreference("inside")} className="pointer">
                            <WorkPreferenceColorDynamic type="inside" size="3rem" color={optionSelectedWhere.includes("inside") ? (type === "Gimmer" ? Colors.Gimmer : Colors.Gim ) : Colors.Manatee} />
                        </button>
                    </div>

                    <div className="flex flex-d-column flex-ai-center">
                        <button onClick={() => handleCurrentWorkPreference("outside")} className="pointer">
                            <WorkPreferenceColorDynamic type="outside" size="3rem" color={optionSelectedWhere.includes("outside") ? (type === "Gimmer" ? Colors.Gimmer : Colors.Gim ) : Colors.Manatee} />
                        </button>
                    </div>
                </div>
            </div>

            <div className="p-relative height-100 width-100">

                <div className="white-bg image-remote-home p-4" style={{ position: "absolute", zIndex: lastOptionClicked === "remote" ? 2 : -1 }}>
                    <RemoteImage />
                </div>

                <div className="map-home p-relative dark-gray-bg">
                    <MapWithSearchBar 
                        address={lastOptionClicked === "outside" ? (outside?.address ?? DefaultPosition.address ) : (inside?.address ?? DefaultPosition.address )} 
                        latitude={lastOptionClicked === "outside" ? (outside?.latitude ?? DefaultPosition.latitude ) : (inside?.latitude ?? DefaultPosition.latitude )} 
                        longitude={lastOptionClicked === "outside" ? (outside?.longitude ?? DefaultPosition.longitude ) : (inside?.longitude ?? DefaultPosition.longitude )}
                        showRadius={lastOptionClicked === "outside" ? true : false}
                        radius={outside?.radius ?? 5}
                        onChangePositionMap={onChangePositionMap}
                        height="27rem"
                        hideSearchBar={true}
                        bottomSlider={true}
                    />
                </div>

                <button onClick={(onPressAddress)} className={`white-bg flex flex-ai-center width-100 address-where ${lastOptionClicked !== "remote" ? "pointer" : ""}`}>
                    { lastOptionClicked !== "remote" && <WorkPreferenceIcon type={lastOptionClicked} size={2.6} /> }
                    <p className="fs-12 ml-14 flex-1 text-align-left">
                        {lastOptionClicked === "inside" && inside.address}
                        {lastOptionClicked === "outside" && outside.address}
                    </p>
                </button>

            </div>

        </div>
    )
}

export default React.memo( Where )