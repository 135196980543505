import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'
import { StageProcess } from '../HomeOffers/homeOffersSlice'
import { OFFER_TIME_SLOT } from '../../../../utils/dates/dates'
import { WorkPreference } from '../ManageOffers/manageOffersDataSlice'

export enum TabNavigatorStage {
    WHERE = 1,
    WHEN = 2,
    RESUME = 3
} 

type InitialState = {
    placeSelected?: any
    slotToRequest?: OFFER_TIME_SLOT
    optionSelectedWhere:    WorkPreferenceStr
    optionsAvailableWhere: WorkPreferenceStr[]
    tabNavigatorStage:      TabNavigatorStage
}


const initialState: InitialState = {
    optionSelectedWhere: "inside",
    optionsAvailableWhere: [  ],
    tabNavigatorStage: TabNavigatorStage.WHERE
}

export const requestProcessSlice = createSlice({
    name: 'requestProcess',
    initialState,
    reducers: {
        setInitialWhereRequestProcessReducer: (state, action: PayloadAction<WorkPreference>) => {
            const workPreference = action.payload

            if(workPreference.remote)
                state.optionsAvailableWhere.push("remote")
            if(workPreference.outside.address)
                state.optionsAvailableWhere.push("outside")
            if(workPreference.inside.address)
                state.optionsAvailableWhere.push("inside")

            return state
        },
        setOptionSelectedWhereRequestProcessReducer: (state, action: PayloadAction<WorkPreferenceStr>) => {
            const option = action.payload
            state.optionSelectedWhere = option
            return state
        },
        setStageProcessRequestReducer: (state, action: PayloadAction<TabNavigatorStage>) => {
            state.tabNavigatorStage = action.payload
            return state
        },
    },
    
    extraReducers: (builder) => {
    },

});

// Action creators are generated for each case reducer function
export const {  
    setStageProcessRequestReducer,
    setOptionSelectedWhereRequestProcessReducer,
    setInitialWhereRequestProcessReducer
} = requestProcessSlice.actions

export default requestProcessSlice.reducer