import React, { useState } from 'react'
import { ProductHistoryTransaction } from '../../../../../utils/dbModels/HistoryTransaction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons'
import { getLinkToDownloadFileProduct } from '../../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions'
import JsFileDownloader from 'js-file-downloader'
import LoadingSpinner from '../../../../LoadingSpinner'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import "./ProductHistoryTransactionItem.css"

type ProductHistoryTransactionItemProps = {
    product: ProductHistoryTransaction
}

const ProductHistoryTransactionItem = ({ product }: ProductHistoryTransactionItemProps) => {

    const [loading, setLoading] = useState(false)

    const onDownload = () => {
        if(!product.File)
            return

        setLoading(true)
        getLinkToDownloadFileProduct(product.id, product.File?.name, "Purchased")
        .then((preSignedUrl) => {
            const link = document.createElement('a');
            link.href = preSignedUrl;
            link.setAttribute('download', `${product.File?.name}.${product.File?.type}`); // Or any desired filename
            link.setAttribute("target", "_blank")
            document.body.appendChild(link);
            //link.click();
            new JsFileDownloader({ 
                url: preSignedUrl
              })
              .then(function () {
                // Called when download ended
              })
              .catch(function (error) {
                // Called when an error occurred
                console.log("error", error)
              }).finally(() => {
                setLoading(false)
              })
        }).catch(() => {
            setLoading(false)
        })
    }

    return (
        <div className="flex flex-ai-center mb-12">
            {
                product.File && 
                <FontAwesomeIcon icon={faFile} className="fs-16 c-black mr-10" />
            }
            <p className="fs-14">{product.name}</p>
            <div className="flex-1 pl-20 pr-20">
                <div className="width-100 line-dashed"></div>
            </div>
            <p className="fs-14">{`${product.price.toFixed(2)}$`}</p>
            {
                product.File &&
                <div className="center ml-10 download-btn-h">
                    {
                        loading ? 
                        <div className="p-relative">
                            <LoadingSpinner mini={true} fullPage={false} />
                        </div>
                        :
                        <button onClick={onDownload} className="pointer">
                            <FontAwesomeIcon icon={faArrowDownToLine} className="fs-18 c-black" />
                        </button>
}
                </div>
            }

        </div>
    )
}

export default React.memo( ProductHistoryTransactionItem )