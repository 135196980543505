import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { changeIndexTabReducer, GetOffersThunk, TypeOffers } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import "./TabBarManageOffers.css"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { canBeInteger } from '../../../utils/verifications/strings'


type TabOfferOption = { index: number }

const colorsAnimation = ["gray","red", "blue"]
const typesOffers = ["all", "Task", "Job"]


const TabBarManageOffers = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const [leftLine, setLeftLine] = useState<number>(0)

    const indexTab = useSelector((state: RootState) => state.manageOffersData.indexTab);
    const limit = useSelector((state: RootState) => state.manageOffersData.limit);
    

    useEffect(() => {
            // Get a specific parameter value
        const limitParam = searchParams.get("limit") ?? "10"
        const pageParam = searchParams.get("page") ?? "1"
        const typeParam = searchParams.get("type") ?? "10"

        let type: TypeOffers = "Task"
        if(typeParam === "Job")
            type = "Job"

        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
        
        let page = 1
        if( canBeInteger(pageParam) )
            page = Number(pageParam)

        let index = 1
        if(type === "Job")
            index = 2

        setTab({ index })
        dispatch(changeIndexTabReducer({ indexTab: index }))
        dispatch(GetOffersThunk({ type, page, limit, bringTotal: true }))

    }, [searchParams.get("page"), searchParams.get("limit"), searchParams.get("type")])

    useEffect(() => {
        if(indexTab < 0)
            setLeftLine(0)
    }, [indexTab])
    

    const setTab = ({ index }: TabOfferOption) => {

        if(index === indexTab)
            return

        const smallLine = document.getElementById("small-line") 

        if(smallLine){

            const marginBetweenOptions = 1; // rem
            const widthOption = 10; // rem

            let remsToMove = 0
            if(indexTab > 0)
                remsToMove = ((index - indexTab) * (widthOption+marginBetweenOptions) );
            else
                remsToMove = ((index - 1) * (widthOption+marginBetweenOptions) ); // case after search bar

            smallLine.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            setLeftLine(remsToMove + leftLine)
            dispatch(changeIndexTabReducer({indexTab : index}))
            navigate(`/manage-offers?limit=${limit}&page=${1}&type=${typesOffers[index] === "Task" ? "Task" : "Job"}`)
            //dispatch(GetOffersThunk({ type: typesOffers[index] === "Task" ? "Task" : "Job", page: 1, limit, bringTotal: true }))
        }
    }

    return (
        <div id="container-tab" className="con-all-options-tab">

            <div 
                className={`option-tab ${indexTab === 1 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 1 })}
            >
                <p className={`text-option-tab ${indexTab === 1  ? "italic text-tab-red" : ""}`}>{t("General:label_036")}</p>
                <div id="1-option" className="option-line"></div>
            </div>
            
            <div 
                className={`option-tab ${indexTab === 2 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 2  })}
            >
                <p className={`text-option-tab ${indexTab === 2  ? "italic text-tab-blue" : ""}`}>{t("General:label_037")}</p>
                <div id="2-option" className="option-line"></div>
            </div>

            <div 
                id="small-line" 
                style={{ left: `${leftLine.toString()}rem` }} 
                className={`small-line ${colorsAnimation[indexTab]}-line ${indexTab < 0 ? "visibility-hidden" : ""}`}
            ></div>
            
            <div className="whole-line"></div>

        </div>
    )
}

// 33cef213-1c57-47aa-814e-16f1bba7bb70
// ${indexTab < 0 ? "visibility-hidden" : ""}
export default React.memo( TabBarManageOffers )